if (personalLoan === undefined) var personalLoan = {}

personalLoan.goodsType = (function () {
    var $goodsType = jQuery('.js-goods-type')
    var $goodsTypeChoiceContainer = $goodsType.find('.js-purchase')
    var $itemTypeHiddenField = jQuery('#itemType')
    var $resultForm = jQuery('#js-result-form');
    var emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    var getTemplateAsset = function (count) {
        return `
        <div class="assets-group-list-item js-new-asset-group-list-item">
            <div class="assets-item assets-item-title new-assets-field">
                <div class="icon">
                    <img class="img-fluid" src="/resources/images/icons/ellipsis-add-new.svg" alt="" />
                </div>
                <div class="form-group">
                    <div class="bka-dropdown">
                        <input type="text" id="heDescription${count}" readonly value="" placeholder="Select" class="js-field-validate" />
                        <div class="options">
                            <div class="option js-tooltip-dropdown" expense-value="EX10" data-tippy-content="Insurance other than home e.g. life, income protection etc.">Other insurance</div>
                            <div class="option js-tooltip-dropdown" expense-value="EX11" data-tippy-content="Child Support, Day care/ After school care">Child support/care</div>
                            <div class="option js-tooltip-dropdown" expense-value="EX12" data-tippy-content="Gym, sport, travel, church, donations">Lifestyle</div>
                            <div class="option js-tooltip-dropdown" expense-value="EX13" data-tippy-content="Savings, Investments, Entertainment, Health equipment/services, Transport, Personal Care, Hobbies">Other</div>
                        </div>
                    </div>
                    <div class="error">Required</div>
                </div>
            </div>

            <div class="assets-item assets-item-input double hidden" id="assets-item-frequency-input">
                <div class="form-group">
                    <label for="">Frequency</label>
                    <div class="bka-dropdown">
                        <input type="text" id="heDescriptionAmount${count}Frequency" value="Monthly" readonly="readonly" placeholder="Select *" class="other-monthly-expenditure-freq js-field-validate js-frequency-amount-calc">
                        <div class="options hidden">
                            <div class="option">Weekly</div>
                            <div class="option">Fortnightly</div>
                            <div class="option">Monthly</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="assets-item assets-item-input double" id="assets-item-payment-input">
                <div class="form-group">
                    <label for="">payments</label>
                    <input id="heDescriptionAmount${count}" type="text" value="" placeholder="0" class="sum-up2 other-monthly-expenditure js-money-control js-frequency-amount-calc" />
                    <div class="icon icon-remove icon-on-right"></div>
                </div>
            </div>
        </div>`
    }

    const isAAMoneyDealer = document.getElementById('isAAMoneyDealer').value === 'true'

    // Change phone number text
    $('#phoneNumber').text($('#hdnPhoneNumber').val());

    jQuery('#js-send-formlink-email').on('click', function () {
        var dealerId = GetDealerId();
        var url = '/api/form/SendApplicationLinkEmail?formId=' + $personalLoanForm.my("data").Id;
        var email = $('#email').val()

        if (dealerId && dealerId !== "" && dealerId !== null) {
            url = '/api/form/SendApplicationLinkEmail?formId=' + $personalLoanForm.my("data").Id + '&dealerid=' + dealerId;
        }

        if (emailRegExp.test(email)) {
            $.ajax({
                type: "POST",
                url: url,
                contentType: 'application/json; charset=utf-8',
                success: function (data) {
                    if (data && data.Data) {
                        $('.email-section').addClass('hidden');
                        $('#email-result').html(data.Message + '<img src="/resources/images/tick.png" style="height:20px;padding-left: 5px;" alt="email sent image">');
                        $('#email-result').removeClass('hidden');
                    }
                },
                error: function (e) {
                    console.log(e)
                }
            });
        }
    });

    // ------------------------------------------
    // Event listeners
    // ------------------------------------------
    $('#noPreviousEmployer').on('change', function () {
        if (isAAMoneyDealer) {
            if($(this).prop("checked") === true)
            {
                $('.js-previous-employer-details-fields').addClass('hidden')
                $personalLoanForm.my("data").Personal.NoPreviousEmployer = true
                $('#previousEmployerName').val('').trigger('change')
                $('#previousOccupation').val('').trigger('change')
                $('#previousEmploymentType').val('').trigger('change')
                $('#previousTimeInJobYears').val('').trigger('change')
            } else {
                $('.js-previous-employer-details-fields').removeClass('hidden')
                $personalLoanForm.my("data").Personal.NoPreviousEmployer = false
            }
        }
    })
    $('#noCoborrowerPreviousEmployer').on('change', function () {
        if (isAAMoneyDealer) {
            if($(this).prop("checked") === true)
            {
                $('.js-co-borrower-previous-employer-details-fields').addClass('hidden')
                $personalLoanForm.my("data").Personal.NoCoBorrowerPreviousEmployer = true
                $('#coBorrowerPreviousEmployerName').val('').trigger('change')
                $('#coBorrowerPreviousOccupation').val('').trigger('change')
                $('#coBorrowerPreviousEmploymentType').val('').trigger('change')
                $('#coBorrowerPreviousTimeInJobYears').val('').trigger('change')
            } else {
                $('.js-co-borrower-previous-employer-details-fields').removeClass('hidden')
                $personalLoanForm.my("data").Personal.NoCoBorrowerPreviousEmployer = false
            }
        }
    })
    $('#residenceType').on('change', function () {
        if (isAAMoneyDealer) {
            if ($(this).val().toLowerCase() === 'living with parents') {
                $('#rentOwnQ').addClass('hidden')
                $('#rentOrOwn').val('Neither').trigger('change')
                makeInputSuccess($('#rentOrOwn'))
            } else {
                $('#rentOwnQ').removeClass('hidden')
                $('#rentOrOwn').val('Select').trigger('change')
                makeInputSuccess($('#rentOrOwn'))
            }
        }
    })

    $('#rentOrOwn').on('change', function () {
        if ($(this).val() === 'Own') {
            $('.js-own-field').each(function () { $(this).removeClass('hidden') });
            $('.js-rent-field').each(function () { $(this).addClass('hidden') });

            $('#mortgageRentMonthlyPayments').val('').trigger('change');
            sumupBoth()
        }

        if ($(this).val() === 'Rent') {
            $('.js-own-field').each(function () { $(this).addClass('hidden') });
            $('.js-rent-field').each(function () { $(this).removeClass('hidden') });

            $('#mortgageAssetValue').val('').trigger('change');
            $('#mortgageAssetBalance').val('').trigger('change');
            $('#mortgageAssetMonthlyPayments').val('').trigger('change');
            $('#ratesExpenditureValue').val('').trigger('change');
            $('#insuranceExpenditureValue').val('').trigger('change');
            sumupBoth()
        }
        if ($(this).val() === 'Neither') {
            $('.js-own-field').each(function () { $(this).addClass('hidden') });
            $('.js-rent-field').each(function () { $(this).addClass('hidden') });
            $('#mortgageAssetValue').val('').trigger('change');
            $('#mortgageAssetBalance').val('').trigger('change');
            $('#mortgageAssetMonthlyPayments').val('').trigger('change');
            $('#ratesExpenditureValue').val('').trigger('change');
            $('#insuranceExpenditureValue').val('').trigger('change');
            $('#mortgageRentMonthlyPayments').val('').trigger('change');
            $('#rentOrOwnOwn').prop('checked', false)
            $('#rentOrOwnRent').prop('checked', false)
            sumupBoth()
        }
    });

    let isAAMemberRadios = document.getElementById("applicationAAMember")
    if (isAAMemberRadios !== null) {
        let aaMembershipSection = $('#aaMembershipNumberSection')
        $('#applicationAAMember').on('change', function () {
            if ($(this).val() === 'Yes') {
                aaMembershipSection.removeClass('hidden');
            } else {
                aaMembershipSection.addClass('hidden');
            }
        });
    }

    let isAAapplicationHasVehicleLoanRadios = document.getElementById("applicationHasVehicleLoan")
    if (isAAapplicationHasVehicleLoanRadios !== null) {
        $('#applicationHasVehicleLoan').on('change', function () {
            if ($(this).val() === 'Yes') {
                $personalLoanForm.my("data").AAPersonal.LoanForVehicle = true
            } else {
                $personalLoanForm.my("data").AAPersonal.LoanForVehicle = false
            }
        });
    }

    let isAALoanForRadios = document.getElementById("loanFor")
    if (isAALoanForRadios !== null) {
        let aaLoanForDefaultSection = $('.js-loan-for-default')
        let aaLoanForDebtConsolidationSection = $('.js-loan-for-consolidate')
        
        $('#loanFor').on('change', function () {
            $personalLoanForm.my("data").AAPersonal.PersonalLoanType = $(this).val()
            if ($(this).val() === 'debt_consolidation') {
                aaLoanForDebtConsolidationSection.removeClass('hidden')
                aaLoanForDefaultSection.addClass('hidden')
                $('#tellUs').val($('#tellUsConsolidate').val())
                $personalLoanForm.my("data").AAPersonal.PersonalLoanReason = $('#tellUs').val()

            } else {
                aaLoanForDefaultSection.removeClass('hidden')
                aaLoanForDebtConsolidationSection.addClass('hidden')
                $('#tellUs').val($('#tellUsDefault').val())
                $personalLoanForm.my("data").AAPersonal.PersonalLoanReason = $('#tellUs').val()
            }
        });
        $('#tellUsDefault').on('change', function () {
            $('#tellUs').val($('#tellUsDefault').val())
            $personalLoanForm.my("data").AAPersonal.PersonalLoanReason = $('#tellUs').val()
        })

        $('#tellUsConsolidate').on('change', function () {
            $('#tellUs').val($('#tellUsConsolidate').val())
            $personalLoanForm.my("data").AAPersonal.PersonalLoanReason = $('#tellUs').val()
        })

        $('#tellUs').on('change', function () {
            $personalLoanForm.my("data").AAPersonal.PersonalLoanReason = $(this).val()
        })

    }

    const consolidateDebtReasonCheckboxesToBind = document.querySelectorAll('input[name="consolidate_debt_reason"]')
    if (consolidateDebtReasonCheckboxesToBind.length > 0) {
        consolidateDebtReasonCheckboxesToBind.forEach(checkbox => {
            checkbox.addEventListener('change', function () {
                let selectedReasons = []
                consolidateDebtReasonCheckboxesToBind.forEach(checkbox => {
                    if (checkbox.checked) {
                        selectedReasons.push(checkbox.value)
                    }
                })
                $personalLoanForm.my("data").AAPersonal.DebtConsolidationReason = selectedReasons
            })
        })
    }
    

    $('#timeInJobYears').on('change', function () {
        if ($(this).val().replace('years', '').replace('year', '').trim() < 3) {
            $('.previous-employer-details').removeClass('hidden');
        } else {
            $('.previous-employer-details').addClass('hidden');
        }
    });

    $('#coBorrowerTimeInJobYears').on('change', function () {
        if ($(this).val().replace('years', '').replace('year', '').trim() < 3) {
            $('.Co-borrower-previous-employer-details').removeClass('hidden');
        } else {
            $('.Co-borrower-previous-employer-details').addClass('hidden');
        }
    });

    $('.js-personal-check-duration').on('change', function () {
        var val = $(this).val();
        if (parseInt(val) < 3) {
            $('.personal-previous-address').show();
        } else {
            $('.personal-previous-address').hide();
        }
    });

    $('.js-license-type').on('change', function () {
        if ($(this).val() === "No Licence") {
            if ($(this).attr("class").split(/\s+/).indexOf('coborrower') > 0) {
                validateLicense($('#coborrowerdriverLicenseVersion'), $('#coborrowerdriverLicenseType'), $('#coborrowerdriverLicenseNumber'));
            } else {
                validateLicense($('#driverLicenseVersion'), $('#driverLicenseType'), $('#driverLicenseNumber'));
            }
        } else {
            if ($(this).attr("class").split(/\s+/).indexOf('coborrower') > 0) {
                $('#coborrowerdriverLicenseVersion').attr('placeholder', 'Driver Licence Version').parent().removeClass('not-needed');
                $('#coborrowerdriverLicenseNumber').attr('placeholder', 'Driver Licence Number').parent().removeClass('not-needed');
            } else {
                $('#driverLicenseVersion').attr('placeholder', 'Driver Licence Version').parent().removeClass('not-needed');
                $('#driverLicenseNumber').attr('placeholder', 'Driver Licence Number').parent().removeClass('not-needed');
            }
        }
    });

    $('.copy-link-btn').on('click', function () {
        $('input.share-save-link').select();
        document.execCommand("copy");
        $('#copy-btn-text').text('COPIED!');
        setTimeout(function () { $('#copy-btn-text').text('COPY') }, 1500);
    });

    $('.js-borrower-check-duration').on('change', function () {
        var val = $(this).val();
        if (parseInt(val) < 3) {
            $('.coborrower-previous-address').show();
        } else {
            $('.coborrower-previous-address').hide();
        }
    });

    $('#sameAddress').on('change', function () {
        var isSameAddress = $(this).val();
        if (isSameAddress === 'Yes') {
            copyAllAddressDetails();
            $personalLoanForm.my("data").Personal.CoBorrowerAddress = $personalLoanForm.my("data").Address;
            $personalLoanForm.my("data").Personal.CoBorrowerCity = $personalLoanForm.my("data").City;
            $personalLoanForm.my("data").Personal.CoBorrowerZIP = $personalLoanForm.my("data").ZIP;
            $(".coborrower-addresswrapper").addClass('hidden');
            $(".borrower-postal-address").addClass('hidden');
            $(".coborrower-previous-address").addClass('hidden');
        }
        else {
            $('#coborroweraddress').val('');
            $('#coborrowercity').val('');
            $('#coborrowerpostcode').val('');
            $personalLoanForm.my("data").Personal.CoBorrowerAddress = '';
            $personalLoanForm.my("data").Personal.CoBorrowerCity = '';
            $personalLoanForm.my("data").Personal.CoBorrowerZIP = '';
            $(".coborrower-addresswrapper").removeClass('hidden');
            $(".borrower-postal-address").removeClass('hidden');
            $(".coborrower-previous-address").removeClass('hidden');
        }
    });

    $('.js-add-asset').on('click', function () {
        var template = $('.temp-other-asset').html();
        $('.js-asset-group').append(template);
        $('.js-amount-validate').on('change', function () {
            _validateAmount($(this));
        });
        // format money control when it is being added
        var moneyControlElem = $('.js-money-control')
        formatMoneyControl(moneyControlElem)
    })

    $('.js-add-commitments').on('click', function () {
        var count = $('.js-commitments-group').find('.js-new-asset-group-list-item-desc').length + 1;
        $('.js-commitments-group').append(`
            <div class="assets-group-list-item js-new-asset-group-list-item-desc">
                <div class="assets-item assets-item-title new-assets-field">
                    <div class="icon">
                        <img class="img-fluid" src="/resources/images/icons/ellipsis-add-new.svg" alt="">
                    </div>
                    <div class="form-group">
                        <label class="sr-only" for="">Value</label>
                        <input type="text" id="fcDescription${count}" placeholder="Description *" maxlength="100" class="fc-description js-desc-control" />
                    </div>
                </div>

                <div class="assets-item assets-item-input double">
                    <div class="form-group">
                        <label for="">Limit</label>
                        <input type="text" value="" id="fcAmount${count}" placeholder="0 *" class="other-limit js-amount-validate js-money-control" />
                    </div>
                </div>

                <div class="assets-item assets-item-input double">
                    <div class="form-group">
                        <label for="">Monthly payments</label>
                        <input type="text" value="" id="fcMonthlyPayments${count}" placeholder="0 *" class="sum-up other-monthly-payments js-money-control" />

                        <div class="icon icon-remove icon-on-right"></div>
                    </div>
                </div>
            </div>`
        )

        $('.js-amount-validate').on('change', function () {
            _validateAmount($(this));
        })

        initSumup(".sum-up", ".assets-item-total");

        // Format money control when it is being added
        var moneyControlElem = $('.js-money-control')
        formatMoneyControl(moneyControlElem)
    })

    $('.js-add-household-expenditure').on('click', function () {
        var count = $('#js-household-expenditure-group').find('.assets-group-list-item').length + 1;
        $('.js-household-expenditure-group').append(getTemplateAsset(count))

        $('.js-amount-validate').on('change', function () {
            _validateAmount($(this));
        });

        initSumup(".sum-up2", ".assets-item-total2")
        toggleDropdownUpdateSumUp2()
        // format money control when it is being added
        var moneyControlElem = $('.js-money-control')
        formatMoneyControl(moneyControlElem)
        initTippy()
    })
    
    $('#applicationSubType').on('change', function () {
        if ($(this).val().toLowerCase() === 'joint') {
            $('.form-wrapper-coborrower').show();
            $('.form-employment-coborrower').show();
            $('.combined-text').show();
            $('#parnerincomeSingle').hide();
        } else {
            $('.form-wrapper-coborrower').hide();
            $('.form-employment-coborrower').hide();
            $('.combined-text').hide();
            $('#parnerincomeSingle').show();
        }
        updateIncomeDecreaseText();
    })

    $('#ddlotherIncome').on('change', function () {
        if ($(this).val().toLowerCase() === 'yes') {
            $('.form-wrapper-other-income').show();
            $('#other-income-attach').parent('.block').removeClass('hidden');
        } else {
            $('#other-income-attach').parent('.block').addClass('hidden');
        }
    })

    $('#incomeDecreaseExpected').on('change', function () {
        if ($(this).val().toLowerCase() === 'yes') {
            $('.form-wrapper-income-decrease').show();
            $('#income-decrease-cob-attach').parent('.block').removeClass('hidden');
        } else {
            $('#income-decrease-cob-attach').parent('.block').addClass('hidden');
        }
    })

    $('#add-income').on('click', function () {
        var count = $('#other-income-attach').find('.row').length + 1;
        var options = $('.incomeType-options').html();
        var html = '<div class="row"> <div class="col-lg-4"> <div class="form-group"> <label>Amount</label> <input type="text" id="Amount' + count + '" value="" class="js-field-validate js-money-control"> <div class="error">Required</div> </div> </div> <div class="col-lg-4"> <div class="form-group"> <label>Frequency</label> <div class="bka-dropdown"> <input type="text" id="frequency' + count + '" value="" readonly placeholder="Select one option" class="js-field-validate"> <div class="options"> <div class="option">Weekly</div> <div class="option">Fortnightly</div> <div class="option">Monthly</div> </div> </div> <div class="error">Required</div> </div> </div> <div class="col-lg-4"> <div class="form-group"><label>Description</label><div class="bka-dropdown"><input type="text" id="incomeType' + count + '" readonly value="" placeholder="Select one option" class="js-field-validate"> <div class="options">' + options + '</div></div><div class="error">Required</div><div class="icon icon-remove icon-on-right"></div></div> </div> </div>';
        $('#other-income-attach').append(html);
        initJsFeildValidations()
        var moneyControlElem = $('.js-money-control')
        formatMoneyControl(moneyControlElem)
    })
    // remove new added income if needed.
    $('.form-wrapper .other-income-attach').on('click', '.row .icon-remove', removeOneItem);

    $('#add-income-co-borrower').on('click', function () {
        var count = $('#other-income-attach-co-borrower').find('.row').length + 1;
        var options = $('.incomeType-options').html();
        var html = '<div class="row"> <div class="col-lg-4"> <div class="form-group"> <label>Amount</label> <input type="text" id="coborrowerAmount' + count + '" value="" class="js-field-validate js-money-control"> <div class="error">Required</div> </div> </div> <div class="col-lg-4"> <div class="form-group"> <label>Frequency</label> <div class="bka-dropdown"> <input type="text" id="coborrowerfrequency' + count + '" value="" readonly placeholder="Select one option" class="js-field-validate"> <div class="options hidden"> <div class="option">Weekly</div> <div class="option">Fortnightly</div> <div class="option">Monthly</div> </div> </div> <div class="error">Required</div> </div> </div> <div class="col-lg-4"> <div class="form-group"><label>Description</label><div class="bka-dropdown"><input type="text" id="coborrowerIncomeType' + count + '" readonly value="" placeholder="Select one option" class="js-field-validate"> <div class="options">' + options + '</div></div><div class="error">Required</div></div> </div> </div>';
        $('#other-income-attach-co-borrower').append(html);
        initJsFeildValidations()
        var moneyControlElem = $('.js-money-control')
        formatMoneyControl(moneyControlElem)
    })

    $('#coborrowerddlotherIncome').on('change', function () {
        if ($(this).val().toLowerCase() === 'yes') {
            $('.form-wrapper-co-other-income').show();
        } else {
            $('.form-wrapper-co-other-income').hide();
        }
    })

    $(".datepicker").datepicker({ changeYear: true, yearRange: "-100:+0", dateFormat: 'dd/mm/yy' });

    $('.datepicker-wrapper img').on("click", function (e) {
        $(".datepicker").datepicker("show");
    });

    $('.js-birth').on('change', function () {
        validateAge($(this));
    });

    $goodsTypeChoiceContainer.on('click', function (event) {
        var closestItem = jQuery(event.target).closest('.item')

        if (closestItem.length > 0) {
            var itemData = closestItem.data()
            personalLoan.app.requestData.GoodsType = itemData.goodType

            $goodsTypeChoiceContainer.find('.item.active').removeClass('active')
            closestItem.addClass('active')

            $itemTypeHiddenField.val(closestItem.attr('data-good-type'))
            $('.js-purchase').removeClass('has-error');
        }
    });

    $goodsType.find('#loanTerm').on('change', function () {
        validateLoanTerm($(this));
    });

    $goodsType.find('#repaymentFrequency').on('change', function () {
        validateFrequencyPayments($(this));
    });

    $goodsType.find('#year').on('change', function () {
        validateInputs([$(this)]);
    });

    const checkVehicleInputs = $goodsType.find('.js-check-vehicle-inputs .js-aa-personal-validate')
    if (checkVehicleInputs.length > 0) {
        checkVehicleInputs.each(function () {
            $(this).on('change', function () {
                validateInputs($(this));
            });
        });
    }

    $goodsType.on('change', '#loanAmount, #loanAmount', function () {
        validateLoanAmount($(this));
    });

    $('.js-purchase .item').on('click', function () {
        $('.js-purchase').attr('style', '');
    });

    initJsFeildValidations();
    initSumup(".sum-up", ".assets-item-total");
    initSumup(".sum-up2", ".assets-item-total2");

    $('.js-number-validate').on('change', function () {
        validatePhone($(this));
    });

    $('.js-amount-validate').on('change', function () {
        _validateAmount($(this))
    });

    $('.btn-save-application').on('click', function () {
        _saveForm(true)
    });

    $('.btn-close-popup').on('click', function () {
        $('.blue-overlay').fadeOut()
    });

    $('.js-email-validate').on('change blur', function () {
        $(this).siblings('.error').addClass('display-block')
        if (validateEmail($(this))) {
            $('.btn-save-application').removeClass('dn-none')
            $(this).siblings('.error').removeClass('display-block')
        } else {
            $('.btn-save-application').addClass('dn-none');
            $(this).siblings('.error').addClass('display-block')
        }
    });

    $('.js-personal-reuse-postal').on("click", function () {
        if ($(this).prop('checked')) {
            $('.personal-postal-address').addClass('hidden');
            CopyAddressDetails(this, '.personal-postal-address');
        } else {
            RemoveCopiedAddressDetails('.personal-postal-address');
            $('.personal-postal-address').removeClass('hidden');
        }
    });

    $('.js-borrower-reuse-postal').on("click", function () {
        if ($(this).prop('checked')) {
            $('.borrower-postal-address').addClass('hidden');
            CopyAddressDetails(this, '.borrower-postal-address');
        } else {
            RemoveCopiedAddressDetails('.borrower-postal-address');
            $('.borrower-postal-address').removeClass('hidden');
        }
    });

    // format money input. e.g 10000 -> 10,000
    function formatMoneyControl(elem) {
        elem.keyup(function (e) {
            if (this.value.charAt(0) === '.' || this.value.charAt(0) === '0') {
                $(this).val('');
            }
            this.value = this.value.replace(/[^0-9\.|\,]/g, '');
            $(this).val(formatMoney($(this).val()));
        });
    }

    function GetDealerId() {
        let dealerId = getParameterByName('dealerid')
        if (dealerId === '' || dealerId === null || dealerId === undefined) {
            dealerId = jQuery("#hdnDealerId").val();
        }
        return dealerId
    }

    function CopyAddressDetails(section, parent) {
        var address = $(section).closest('.form-wrapper').find('.cp-address').val();
        var city = $(section).closest('.form-wrapper').find('.cp-city').val();
        var postcode = $(section).closest('.form-wrapper').find('.cp-postcode').val();
        var country = $(section).closest('.form-wrapper').find('.cp-country').val();
        $(parent).find('.js-postal-address').val(address);
        $(parent).find('.js-postal-address').trigger('change');
        $(parent).find('.js-postal-city').val(city);
        $(parent).find('.js-postal-city').trigger('change');
        $(parent).find('.js-postal-code').val(postcode);
        $(parent).find('.js-postal-code').trigger('change');
        $(parent).find('.js-postal-country').val(country);
        $(parent).find('.js-postal-country').trigger('change');
    }

    function RemoveCopiedAddressDetails(parent) {
        $(parent).find('.js-postal-address').val('');
        $(parent).find('.js-postal-city').val('');
        $(parent).find('.js-postal-code').val('');
        $(parent).find('.js-postal-country').val('');
    }

    function copyAllAddressDetails() {
        var $address = $("#address");
        var $city = $("#city");
        var $postcode = $("#postcode");
        var $country = $("#country");
        var $residenceType = $("#residenceType");
        var $timeAtAddressYears = $("#timeAtAddressYears");
        var $timeAtAddressMonths = $("#timeAtAddressMonths");

        var $postAddress = $("#postAddress");
        var $postcity = $("#postcity");
        var $postZipCode = $("#postZipCode");
        var $postcountry = $("#postcountry");

        var $previousAddress = $("#previousAddress");
        var $previousCity = $("#previousCity");
        var $previousPostcode = $("#previousPostcode");
        var $previousCountry = $("#previousCountry");
        var $previousTimeAtAddressYears = $("#previousTimeAtAddressYears");
        var $previousTimeAtAddressMonths = $("#previousTimeAtAddressMonths");

        var $coborroweraddress = $("#coborroweraddress");
        var $coborrowercity = $("#coborrowercity");
        var $coborrowerpostcode = $("#coborrowerpostcode");
        var $coborrowercountry = $("#coborrowercountry");
        var $coborrowerResidenceType = $("#coborrowerResidenceType");
        var $coborrowertimeAtAddressYears = $("#coborrowertimeAtAddressYears");
        var $coborrowertimeAtAddressMonths = $("#coborrowertimeAtAddressMonths");
        var $coBorrowerPostAddress = $("#coBorrowerPostAddress");
        var $coBorrowerPostCity = $("#coBorrowerPostCity");
        var $coBorrowerPostZIP = $("#coBorrowerPostZIP");
        var $coBorrowerPostCountry = $("#coBorrowerPostCountry");

        var $coborrowerPreviousAddress = $("#coborrowerPreviousAddress");
        var $coborrowerPreviousCity = $("#coborrowerPreviousCity");
        var $coborrowerPreviousPostcode = $("#coborrowerPreviousPostcode");
        var $coborrowerPreviousCountry = $("#coborrowerPreviousCountry");
        var $coborrowerPreviousTimeAtAddressYears = $("#coborrowerPreviousTimeAtAddressYears");
        var $coborrowerPreviousTimeAtAddressMonths = $("#coborrowerPreviousTimeAtAddressMonths");

        $coborroweraddress.val($address.val()); $coborroweraddress.trigger('change');
        $coborrowercity.val($city.val()); $coborrowercity.trigger('change');
        $coborrowerpostcode.val($postcode.val()); $coborrowerpostcode.trigger('change');
        $coborrowercountry.parent().find('.option').each(function () {
            if ($(this).text() == $country.val()) {
                $(this).addClass('selected').trigger('click');
            }
        });

        $coborrowerResidenceType.parent().find('.option').each(function () {
            if ($(this).text() == $residenceType.val()) {
                $(this).addClass('selected').trigger('click');
            }
        });

        $coborrowertimeAtAddressYears.val($timeAtAddressYears.val()); $coborrowertimeAtAddressYears.trigger('change');
        $coborrowertimeAtAddressMonths.val($timeAtAddressMonths.val()); $coborrowertimeAtAddressMonths.trigger('change');
        $coBorrowerPostAddress.val($postAddress.val()); $coBorrowerPostAddress.trigger('change');
        $coBorrowerPostCity.val($postcity.val()); $coBorrowerPostCity.trigger('change');
        $coBorrowerPostZIP.val($postZipCode.val()); $coBorrowerPostZIP.trigger('change');

        $coBorrowerPostCountry.parent().find('.option').each(function () {
            if ($(this).text() == $postcountry.val()) {
                $(this).addClass('selected').trigger('click');
            }
        });

        $coborrowerPreviousAddress.val($previousAddress.val()); $coborrowerPreviousAddress.trigger('change');
        $coborrowerPreviousCity.val($previousCity.val()); $coborrowerPreviousCity.trigger('change');
        $coborrowerPreviousPostcode.val($previousPostcode.val()); $coborrowerPreviousPostcode.trigger('change');

        $coborrowerPreviousCountry.parent().find('.option').each(function () {
            if ($(this).text() == $previousCountry.val()) {
                $(this).addClass('selected').trigger('click');
            }
        });

        $coborrowerPreviousTimeAtAddressYears.val($previousTimeAtAddressYears.val()); $coborrowerPreviousTimeAtAddressYears.trigger('change');
        $coborrowerPreviousTimeAtAddressMonths.val($previousTimeAtAddressMonths.val()); $coborrowerPreviousTimeAtAddressMonths.trigger('change');
    }

    function _saveForm(openPopup) {
        var formData = JSON.parse(JSON.stringify($personalLoanForm.my('data')))
        var purchaseItems = formData.PurchaseItems
        formData.PurchaseItems = []
        formData.PurchaseItems.push(purchaseItems)
        formData.OtherAssets = _getAssets()
        var sum = 0
        formData.OtherAssets.forEach(function (item) {
            sum += parseInt(item.Amount)
        })

        formData.Asset.OtherValue = parseFloat(sum)
        formData.Asset.OtherMonthlyPayment = _getOtherMonthlyPaymentSum()
        formData.Asset.OtherAmount = _getOtherAssetAmountSum()
        formData.Asset.OtherRecurringExpenses = _getOtherRecurringExpenses()
        formData.OtherFinancialCommitments = _getOtherFinancialCommits()
        formData.OtherIncomes = personalLoan.goodsType.getOtherIncomes()

        let dealerId = GetDealerId()
        if (dealerId) {
            formData.dealerId = dealerId
        }

        $.ajax({
            type: 'POST',
            url: '/api/form/SaveForm',
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data: JSON.stringify(formData),
            success: function (data) {
                if (data && data !== '00000000-0000-0000-0000-000000000000') {
                    $personalLoanForm.my('data').Id = data
                    if (openPopup) {
                        launchSavePopup(data)
                    }
                }
            },
            error: function (e) {
                console.log(e)
            }
        })
    }

    function launchSavePopup(id) {
        if (getParameterByName('embed') == 'true') {
            var frameParentUrl = null;
            frameParentUrl = (window.location != window.parent.location)
                ? document.referrer.replace('#', '')
                : document.location.href.replace('#', '');
            if (frameParentUrl.indexOf('?') <= 0) {
                frameParentUrl += '?';
                if (GetDealerId() === null) {
                    frameParentUrl += 'dealerid=' + GetDealerId();
                }
            } else {
                if (GetDealerId() === null) {
                    frameParentUrl += '&dealerid=' + GetDealerId();
                }
            }

            if (getParameterByName('embed') === null) {
                frameParentUrl += '&embed=true';
            }

            if (getParameterByName('formid') === null) {
                frameParentUrl += `&formid=${id}`;
            }

            $('#saved-link').val(`${frameParentUrl}`);
        }
        else {
            $('#saved-link').val(`${window.location.href.split('?')[0].replace('#', '')}?formid=${id}`);
        }

        $('.email-section').addClass('hidden');
        $('#email-result').addClass('hidden').html('');
        $('#popup-name').text(' ' + $('#firstName').val());
        var email = $('#email').val();
        if (emailRegExp.test(email)) {
            $('.email-section').removeClass('hidden');
        } else {
            $('.email-section').addClass('hidden');
        }
        $('.blue-overlay').fadeIn();
    }

    function _submitForm(reCaptchaID) {
        let token = grecaptcha.getResponse(reCaptchaID)
        let d = JSON.parse(JSON.stringify($personalLoanForm.my('data')))
        let purchaseItems = d.PurchaseItems

        d.PurchaseItems = []
        d.PurchaseItems.push(purchaseItems)
        d.OtherFinancialCommitments = _getOtherFinancialCommits()
        d.Asset.OtherRecurringExpenses = _getOtherRecurringExpenses()

        let dealerId = GetDealerId()
        let url = '/api/form/SubmitForm?reCapToken=' + token

        if (dealerId && dealerId !== "" && dealerId !== null) {
            url = '/api/form/SubmitForm?reCapToken=' + token + '&dealerid=' + dealerId
        }

        $('.mask-wrapper').removeClass('hidden')   //show loading mask
        $('.header-items').addClass('hidden')     // hide header options
        $.ajax({
            type: "POST",
            url: url,
            contentType: 'application/json; charset=utf-8',
            dataType: 'json',
            data: JSON.stringify(d),
            beforeSend: function () {
                if (hasFilesToUpload(personalLoan.applicationOptions.uploadFormFilesArr)) {
                    // Add uploading file info to loading box
                    displayUploadFileInfo(personalLoan.applicationOptions.uploadFormFilesArr.length)
                }
            },
        })
            .done(function (response) {
                if (hasFilesToUpload(personalLoan.applicationOptions.uploadFormFilesArr)) {
                    if (!response.Success) return

                    personalLoan.applicationOptions.submittedFormId = response.Data.FormId

                    // handle files upload
                    handleDocsUpload(personalLoan.applicationOptions.uploadFormFilesArr, response.Data.FormId)
                        .then((values) => {
                            displayFileUploadStatus(values)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(() => {
                            setTimeout(function () {
                                if (!!response.Data) {
                                    personalLoan.app.thanksYou(response)
                                    window.uploadFormFilesAr = []
                                    delete window.hasUsedUpload
                                } else {
                                    alert(response.Message)
                                }
                                // Hide loading mask
                                $('.mask-wrapper').addClass('hidden')
                            }, 1500)
                        })

                } else {
                    if (!!response.Data) {
                        personalLoan.app.thanksYou(response)
                        delete window.hasUsedUpload
                    } else {
                        alert(response.Message)
                    }
                    // Hide loading mask
                    $('.mask-wrapper').addClass('hidden')
                }
            })
            .fail(function (error) {
                alert(error)
            });
    }

    function displayFileUploadStatus(files) {
        const thankYouHeading = document.querySelector('#thank-you-heading')
        if (!!files && !!thankYouHeading) {
            const fileUploadStatusDiv = (files) => {
                return (
                    `<div class="file-upload-status">
                        <div class="status-row">
                            <div class="status-col status-col--aa-tick">
                                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.666 7.33268H15.3327V9.99935H12.666V7.33268ZM12.666 12.666H15.3327V20.666H12.666V12.666ZM13.9993 0.666016C6.63935 0.666016 0.666016 6.63935 0.666016 13.9993C0.666016 21.3593 6.63935 27.3327 13.9993 27.3327C21.3593 27.3327 27.3327 21.3593 27.3327 13.9993C27.3327 6.63935 21.3593 0.666016 13.9993 0.666016ZM13.9993 24.666C8.11935 24.666 3.33268 19.8793 3.33268 13.9993C3.33268 8.11935 8.11935 3.33268 13.9993 3.33268C19.8793 3.33268 24.666 8.11935 24.666 13.9993C24.666 19.8793 19.8793 24.666 13.9993 24.666Z" fill="#0082BB"/>
                                </svg>
                            </div>
                            <div class="status-col">
                                <h2>File upload completed</h2>
                                <p>
                                   <span id="js-succeed-uploaded">${files.filter(file => file.Issuccess).length}</span> / ${files.length} files were uploaded successfully
                                </p>
                            </div>
                        </div>
                        
                        ${files.filter(file => !file.Issuccess).length > 0 ?
                        `
                                ${files.filter(file => !file.Issuccess).length > 0 ?
                            `<p class="error-message">
                                        There was an error while uploading ${files.filter(file => !file.Issuccess).length} of your files
                                    </p>`
                            :
                            ``
                        }
                                
                                <div class="status-col">
                                ${files.filter(file => !file.Issuccess).map(file => {
                            return (
                                `<div class="single-file-upload-status status-row">
                                                <div class="${file.Issuccess}-uploaded">
                                                    <div class="status-row">
                                                        <svg class="status-error-svg" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M10 0H2C0.9 0 0.0100002 0.9 0.0100002 2L0 18C0 19.1 0.89 20 1.99 20H14C15.1 20 16 19.1 16 18V6L10 0ZM2 18V2H9V7H14V18H2Z" fill="#E74C3C"/>
                                                        </svg>
                                                    </div>
                                                    <div class="status-col">
                                                        <div class="single-file-info">
                                                            <span>
                                                                ${file.ErrorResponse.fileName}
                                                            </span>
                                                            <span>
                                                                <strong>
                                                                    ${(personalLoan.applicationOptions.uploadFormFilesArr.find(function (f) {
                                    return f.name === file.ErrorResponse.fileName
                                }).size / (1024 * 1024)).toFixed(2) + 'mb'
                                }
                                                                </strong>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="retry-button">
                                                    <button class="reupload-button" data-filename="${file.ErrorResponse.fileName}">
                                                        Retry
                                                    </button>
                                                </div>
                                            </div>`
                            )
                        }).join('')
                        }
                                </div>
                            `
                        :
                        ``
                    }

                        ${files.filter(file => !file.Issuccess).length > 0 ?
                        `<p class="note-message">
                                <strong>Note:</strong> your application will still be processed
                            </p>`
                        :
                        ''
                    }
                    </div>`
                )
            }

            thankYouHeading.insertAdjacentHTML("afterend", fileUploadStatusDiv(files))

            // Launch reupload funciton if there is any
            setTimeout(function () {
                tryReUploadFile()
            }, 500)

        }
    }

    function displayUploadFileInfo(length) {
        const loadingImgWrapper = document.querySelector('.mask-wrapper .img-wrapper')
        if (!!loadingImgWrapper) {
            let html = `
                <div class="file-upload-info-box">
                    <h2>Uploading your files</h2>
                    <div>File <span id="js-uploaded-file">0</span> / ${length}</div>
                </div>`
            loadingImgWrapper.insertAdjacentHTML('afterend', html)
        }
    }

    function hasFilesToUpload(filesArr) {
        if (!!filesArr && filesArr.length > 0) {
            return true
        } else {
            return false
        }
    }

    function uploadedItem(index) {
        const jsUploadedItem = document.querySelector('#js-uploaded-file')
        jsUploadedItem.innerText = index + 1
    }

    async function handleDocsUpload(files, submittedFormId) {
        const uploadPromises = []

        files.forEach((file, index) => {
            const uploadPromise = new Promise((resolve, reject) => {
                setTimeout(function () {
                    let formData = new FormData()
                    formData.append('file', file)

                    $.ajax({
                        url: `/api/form/UploadDocument?formId=${submittedFormId}&uploadAttempt=false`,
                        type: 'POST',
                        data: formData,
                        cache: false,
                        processData: false,
                        contentType: false,
                        beforeSend: function () {
                            setTimeout(function () {
                                uploadedItem(index)
                            }, index * 800)
                        }
                    }).done(function (response) {
                        resolve(response)
                    }).fail(function (err) {
                        reject(err)
                    })
                }, index * 1000)
            })

            uploadPromises.push(uploadPromise)
        })

        return Promise.all(uploadPromises)
            .then((values) => {
                return values
            }
            )
    }

    function handleDocsReUpload(file, submittedFormId) {
        const reuploadPromise = new Promise((resolve, reject) => {
            let formData = new FormData()
            formData.append('file', file)

            $.ajax({
                url: `/api/form/UploadDocument?formId=${submittedFormId}&uploadAttempt=true`,
                type: 'POST',
                data: formData,
                cache: false,
                processData: false,
                contentType: false,
            }).done(function (response) {
                setTimeout(function () {
                    resolve(response)
                }, 3000)

            }).fail(function (err) {
                reject(err)
            })
        })

        return reuploadPromise.then(value => { return value })
    }

    function handleReUploadStatus(button, res) {
        const jsSucceedUploaded = document.querySelector('#js-succeed-uploaded')
        if (!!jsSucceedUploaded && !!res) {
            if (res.Issuccess) {
                let succeedUploadedFiles = parseInt(jsSucceedUploaded.innerText)
                succeedUploadedFiles++
                jsSucceedUploaded.innerText = succeedUploadedFiles

                removeMessageFromReUpload(button)
            }
        }
    }

    function removeMessageFromReUpload(button) {
        const parentRow = button.closest('.single-file-upload-status.status-row')
        parentRow.remove()

        removeErrorNoteMessage()
    }

    // Handle Reupload button
    function tryReUploadFile() {
        let reUploadButtons = document.getElementsByClassName("reupload-button")
        for (let i = 0, ii = reUploadButtons.length; i < ii; i++) {
            reUploadButtons[i].addEventListener("click", function () {
                let $this = this
                const invalidFileName = $this.dataset.filename
                const reUploadFile = personalLoan.applicationOptions.uploadFormFilesArr.find(({ name }) => name === invalidFileName)
                // Reuploading file
                reUploadingFile($this, true)
                // Reupload file individually
                handleDocsReUpload(reUploadFile, personalLoan.applicationOptions.submittedFormId)
                    .then((value) => {
                        if (!!value) {
                            const res = value
                            handleReUploadStatus($this, res)

                            // Turn off reuploading status
                            setTimeout(function () {
                                reUploadingFile($this, false, res.Issuccess)
                            }, 3000)
                        }
                    })
            }, false)
        }
    }

    function reUploadingFile(button, isLoading, status) {
        const parentFalseUploaded = button.closest('.single-file-upload-status.status-row')

        if (isLoading) {
            let svgHtml = `
                <svg class="reupload-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M17.65,6.35C16.2,4.9 14.21,4 12,4A8,8 0 0,0 4,12A8,8 0 0,0 12,20C15.73,20 18.84,17.45 19.73,14H17.65C16.83,16.33 14.61,18 12,18A6,6 0 0,1 6,12A6,6 0 0,1 12,6C13.66,6 15.14,6.69 16.22,7.78L13,11H20V4L17.65,6.35Z" />
                </svg>
            `
            button.innerHTML = `${svgHtml} uploading`

            if (!!parentFalseUploaded) {
                parentFalseUploaded.classList.add('uploading')
            }
        } else if (!status) {
            button.innerHTML = `Retry`

            if (!!parentFalseUploaded) {
                parentFalseUploaded.classList.remove('uploading')
            }
        }
    }

    function removeErrorNoteMessage() {
        const errorUploadedFile = document.getElementsByClassName('.single-file-upload-status')

        if (errorUploadedFile.length === 0) {
            document.querySelector('.file-upload-status .error-message').remove()
            document.querySelector('.file-upload-status .note-message').remove()
        }
    }

    function initJsFeildValidations() {
        $('.js-field-validate').on('change', function () {
            validateInputs([$(this)]);
        });
    }

    function initSumup(el, totalEl) {
        $(el).on("blur", function () {
            $(totalEl).text(sumupElValues(el));
        });
    }

    function sumupElValues(el) {
        var sum = 0;
        $(el).each(function () {
            sum += GetSanitizedValue(this);
        });
        return '$ ' + sum.toFixed(2);
    }

    function toggleDropdownUpdateSumUp2() {
        $('.js-frequency-amount-calc').on('change', function () {
            $(".assets-item-total2").text(sumupElValues(".sum-up2"))
        })
    }

    toggleDropdownUpdateSumUp2()

    function sumupBoth() {
        $(".assets-item-total").text(sumupElValues(".sum-up"));
        $(".assets-item-total2").text(sumupElValues(".sum-up2"));
    }
    // remove new asset item field
    $('.form-wrapper .js-asset-group').on('click', '.assets-group-list-item .icon-remove', removeOneItem);

    // remove new commitment item field
    $('.form-wrapper .js-commitments-group').on('click', '.assets-group-list-item .icon-remove', removeOneItem);

    // remove new income field
    $('.form-wrapper-other-income').on('click', '.row .icon-remove', removeOneItem);

    // remove new household expenditure field
    $('.form-wrapper .js-household-expenditure-group').on('click', '.assets-group-list-item .icon-remove', removeOneItem);

    // Added sumup function callback to sumup totals on item removal as well.
    function removeOneItem() {
        $(this).parent().parent().parent().remove();
        sumupBoth();
    }

    function CurrencyFormatted(amount) {
        if (amount.indexOf('-') > -1) {
            return '$ 0';
        }
        var delimiter = ","; // replace comma if desired
        if (amount.indexOf('.') === -1) {
            amount = amount + ".00";
        }
        var a = amount.split('.', 2);
        var d = a[1];
        var i = parseInt(a[0].replace(/,/g, '').replace(/ /g, '').replace(/\$/g, '')); //remove all delimiter , and remove all $ symbol
        if (isNaN(i)) {
            return '$ 0';
        }
        var minus = '';
        if (i < 0) {
            minus = '-';
        }
        i = Math.abs(i);
        var n = new String(i);
        var a = [];
        while (n.length > 3) {
            var nn = n.substr(n.length - 3);
            a.unshift(nn);
            n = n.substr(0, n.length - 3);
        }
        if (n.length > 0) {
            a.unshift(n);
        }
        n = a.join(delimiter);
        if (d.length < 1) {
            amount = n;
        } else {
            amount = n + '.' + d;
        }
        amount = minus + amount;
        return '$ ' + amount;
    }

    function _addPurchaseItem() {
        var data = _getData();
        if (data) {
            var model = $personalLoanForm.my("data")
            model.PurchaseItems = data;
            $personalLoanForm.my("data", model);

            dataLayer.push({
                'event': 'purchase-form-data',
                'applicationType': data.ItemType,
                'loanAmount': data.PurchasePrice - data.CashDeposit,
                'loanTerm': data.LoanTerm
            });
            
            _setValues(data);
        }
    }

    function onClickScrollToError(elem, elemIndex, time) {
        $('html, body').animate({
            scrollTop: elem.eq(elemIndex).offset().top - $(window).height()/2
        }, time);
    }

    function _getData() {
        if (!_isFormValid()) {
            onClickScrollToError($('.has-error'), 0, 600)
            $resultForm.attr("valid", false);
            return
        }
        $resultForm.attr("valid", true);
        $resultForm.show();
        const aaLoanType = $('#loanTypeAAMoney').val()
        if (aaLoanType && aaLoanType === 'aa-personal') {
            return {
                ItemType: jQuery('#itemType').val(),
                Year: '',
                New: false,
                Used: false,
                PurchasePrice: parseFloat(formatPrice(jQuery('#loanAmount').val())),
                Make: jQuery('#make').val(),
                Model: jQuery('#model').val(),
                Year: jQuery('#year').val(),
                LoanTerm: jQuery('#loanTerm').val(),
                RepaymentFrequency: jQuery('#repaymentFrequency').val()
            };
        } else {
            return {
                ItemType: jQuery('#itemType').val(),
                Year: '',
                New: false,
                Used: false,
                PurchasePrice: parseFloat(formatPrice(jQuery('#loanAmount').val())),
                CashDeposit: parseFloat(formatPrice(jQuery('#deposit').val())),
                Make: jQuery('#make').val(),
                Model: jQuery('#model').val(),
                Year: jQuery('#year').val(),
                LoanTerm: jQuery('#loanTerm').val(),
                RepaymentFrequency: jQuery('#repaymentFrequency').val()
            };
        }
        
    }
    function _setValues(formData) {
        try {
            var itemImageUrl = getImage(formData.ItemType);
            var cardIconUrl = getCardIcon(formData.ItemType);
            var title = $('.js-purchase').find("[data-good-type=" + formData.ItemType + "]").find('.description').text().trim();
            const aaLoanType = $('#loanTypeAAMoney').val()
            if (aaLoanType && aaLoanType === 'aa-personal') {
                title = 'Personal Loan'
            }
            $resultForm.find('.card-head').find('img:not(.card-icon)').attr('src', itemImageUrl);
            if (title === '') {
                $resultForm.find('.card-head').find('span').text('Car or Van');
            } else {
                $resultForm.find('.card-head').find('span').text(title);
            }
            $goodsType.find('#mainForm').hide();
            $goodsType.find('.js-edit-form').on('click', function () {
                $goodsType.find('#mainForm').show();
                $resultForm.hide();
            });

            setTimeout(function () {
                $('.card-head.active img.card-icon').attr('src', cardIconUrl)
            }, 100)

            $('.loan-details').each(function (i) {
                $(this).find('.img-section').attr('data-item', itemImageUrl)
                if (aaLoanType && aaLoanType === 'aa-vehicle') {
                    if (isNaN(formData.CashDeposit))  {
                        $(this).find('.loan-payment').text('$' + formData.PurchasePrice)
                    } else {
                        $(this).find('.loan-payment').text('$' + (formData.PurchasePrice - formData.CashDeposit))
                    }
                } else {
                    $(this).find('.loan-payment').text('$' + formData.PurchasePrice)
                }

                if (isAAMoneyDealer) {
                    $(this).find('.loan-counter div').text('With ' + formData.RepaymentFrequency.toLowerCase() + ' payments')
                    $(this).find('.loan-counter div.d-flex').text('over ' + getTermText(formData.LoanTerm))
                } else {
                    $(this).find('.loan-counter div').text(formData.RepaymentFrequency + ' payments')
                    $(this).find('.loan-counter div.d-flex').text(getTermText(formData.LoanTerm))
                }
                
                $(this).parent().removeClass('dn-none').addClass('udc-full-width')
                $('body').addClass('show-save-button')
            });
            setTimeout (function () {
                $('html, body').animate({
                    scrollTop: 100
                }, 300);
            }, 1)
        } catch (e) {
            console.error(e)
        }
    }
    function getTermText(timeSpan) {
        if (timeSpan) {
            if (isAAMoneyDealer) {
                return (timeSpan.charAt(0) * 12) + ' Months'
            } else {
                return (timeSpan.charAt(0) * 12) + ' months term'
            }
        }
    }
    function getImage(itemType) {
        var itemImageUrl = '';
        switch (itemType) {
            case 'MV':
                itemImageUrl = 'car';
                break;
            case 'RM':
                itemImageUrl = 'motorbike';
                break;
            case 'TB':
                itemImageUrl = 'boat';
                break;
            case 'MH':
                itemImageUrl = 'other';
                break;
            default:
                break;
        }

        return itemImageUrl;
    }
    function getCardIcon(itemType) {
        var cardIconUrl = '';

        switch (itemType) {
            case 'MV':
                cardIconUrl = '/resources/images/icons/car-white.svg';
                break;
            case 'RM':
                cardIconUrl = '/resources/images/icons/bike-white.svg';
                break;
            case 'TB':
                cardIconUrl = '/resources/images/icons/boat-white.svg';
                break;
            case 'MH':
                cardIconUrl = '/resources/images/icons/other-white.svg';
                break;
            default:
                break;
        }

        return cardIconUrl;
    }
    function validateLoanTerm(selectedVal) {
        if (selectedVal.val() !== "") {
            $(selectedVal).parent().parent().find('.error').html('Required');
            $(selectedVal).parent().parent().find('label').attr('style', '');
            $(selectedVal).parent().parent().find('input').attr('style', '');
            $(selectedVal).parent().parent().find('.error').attr('style', '');
            $(selectedVal).parent().removeClass('has-error');
            return true;
        } else {
            $(selectedVal).parent().parent().find('.error').html('Select loan term');
            $(selectedVal).parent().parent().find('label').attr('style', 'color:red');
            $(selectedVal).parent().parent().find('input').attr('style', 'border-color:red');
            $(selectedVal).parent().parent().find('.error').attr('style', 'display:block');
            $(selectedVal).parent().addClass('has-error');
            return false;
        }
    }
    function validateLoanAmount(purchasePrice) {
        const $purchasePriceParentElement = $(purchasePrice).parent()
        const minimumBorrow = parseFloat($('#hdnMinimumLoanAmount').val())
        if (!purchasePrice.val() || formatPrice(purchasePrice.val()) < minimumBorrow) {
            $purchasePriceParentElement.find('.error').html(`Min. amount must be $${formatMoney(minimumBorrow)}`)
            $purchasePriceParentElement.find('label').attr('style', 'color:red')
            $purchasePriceParentElement.find('input').attr('style', 'border-color:red')
            $purchasePriceParentElement.find('.error').attr('style', 'display:block')
            $purchasePriceParentElement.addClass('has-error')
            return false
        } 

        const hdnMaximumLoanAmountValue = $('#hdnMaximumLoanAmount').val()
        if (hdnMaximumLoanAmountValue && formatPrice(purchasePrice.val()) > parseFloat(hdnMaximumLoanAmountValue)) {
            $purchasePriceParentElement.find('.error').html(`Max. amount must be $${formatMoney(hdnMaximumLoanAmountValue)}`)
            $purchasePriceParentElement.find('label').attr('style', 'color:red')
            $purchasePriceParentElement.find('input').attr('style', 'border-color:red')
            $purchasePriceParentElement.find('.error').attr('style', 'display:block')
            $purchasePriceParentElement.addClass('has-error')
            return false
        }

        // If no errors set the styles back to normal
        $purchasePriceParentElement.find('.error').html('Required')
        $purchasePriceParentElement.find('label').attr('style', '')
        $purchasePriceParentElement.find('input').attr('style', '')
        $purchasePriceParentElement.find('.error').attr('style', '')
        $purchasePriceParentElement.removeClass('has-error')
        return true
    }
    
    function _validateAmount(val) {
        var numeric = formatPrice(val.val());
        if (numeric !== '') {
            var valid = /^\d{0,11}(\.\d{0,2})?$/.test(numeric);
            if (valid) {
                //valid name
                $(val).parent().find('label').attr('style', '');
                $(val).parent().find('input').attr('style', '');
                $(val).parent().find('.error').attr('style', '');
                $(val).parent().find('.error').html('Required');
                $(val).parent().removeClass('has-error');
                return true;
            } else {
                //invalid name
                $(val).parent().find('label').attr('style', 'color:red');
                $(val).parent().find('input').attr('style', 'border-color:red');
                $(val).parent().find('.error').attr('style', 'display:block');
                $(val).parent().find('.error').html('Invalid phone number');
                $(val).parent().addClass('has-error');
                return false;
            }
        }
    }
    function validateFrequencyPayments(selectedVal) {
        if (selectedVal.val() !== "") {
            $(selectedVal).parent().parent().find('.error').html('Required');
            $(selectedVal).parent().parent().find('label').attr('style', '');
            $(selectedVal).parent().parent().find('input').attr('style', '');
            $(selectedVal).parent().parent().find('.error').attr('style', '');
            $(selectedVal).parent().removeClass('has-error');
            return true;
        } else {
            $(selectedVal).parent().parent().find('.error').html('Select repayment frequency');
            $(selectedVal).parent().parent().find('label').attr('style', 'color:red');
            $(selectedVal).parent().parent().find('input').attr('style', 'border-color:red');
            $(selectedVal).parent().parent().find('.error').attr('style', 'display:block');
            $(selectedVal).parent().addClass('has-error');
            return false;
        }
    }

    function validatePurchaseType() {
        if ($('#itemType').val() !== '') {
            $('.js-purchase').attr('style', '').removeClass('has-error');
            return true;
        } else {
            $('.js-purchase').attr('style', 'border:1px solid red').addClass('has-error');
            return false;
        }
    }

    function formatPrice(price) {
        return price.replace('$', '').replace(/ /g, '').replace(/,/g, '');
    }

    function validateConsolidateDebtReason(name) {
        const checkedCheckboxes = document.querySelectorAll('input[name="' + name + '"]:checked')
        if (checkedCheckboxes.length == 0) {
            $('#consolidateDebtReason').parent().parent().addClass('checkbox-has-error')
            return false
        } else {            
            $('#consolidateDebtReason').parent().parent().removeClass('checkbox-has-error')
            return true
        }
    }

    function _isFormValid() {
        var loanAmount = validateLoanAmount($goodsType.find('#loanAmount'));
        var loanTerm = validateLoanTerm($goodsType.find('#loanTerm'));
        var loanFreq = validateFrequencyPayments($goodsType.find('#repaymentFrequency'));
        var year = validateInputs([$('#year')]);
        var type = validatePurchaseType();
        var aaMember = ''
        var aaMembershipNumber = ''


        const loanTypeAAMoney = $('#loanTypeAAMoney')
        const isAAMember = document.getElementById("applicationAAMember")

        if (loanTypeAAMoney !== null && loanTypeAAMoney.val() === 'aa-vehicle') {
            aaMember = validateInputs([isAAMember])
            if (aaMember && $('#applicationAAMember').val() === 'Yes') {
                aaMembershipNumber = validateAAMembershipNumber($('#aaMoneyMembershipNumber'))
                return (loanAmount && loanTerm && loanFreq && type && year && aaMember && aaMembershipNumber)
            } else {
                return (loanAmount && loanTerm && loanFreq && type && year && aaMember)
            }
        } else if (loanTypeAAMoney !== null && loanTypeAAMoney.val() === 'aa-personal') {
            aaMember = validateInputs([isAAMember])
            const vehicleInputs = validateInputs($('.js-check-vehicle-inputs .js-aa-personal-validate'));
            if (vehicleInputs === true) {
                const vehicles = document.querySelectorAll('.js-aa-personal-vehicle-wrapper')

                let vehicleData = []

                vehicles.forEach(function (vehicle) {
                    if (vehicle.querySelector('.js-aa-vehicle-registration').value !== '') {
                        vehicleData.push({
                            Registration: vehicle.querySelector('.js-aa-vehicle-registration').value,
                            Make: vehicle.querySelector('.js-aa-vehicle-make').value,
                            Model: vehicle.querySelector('.js-aa-vehicle-model').value,
                            ModelYear: vehicle.querySelector('.js-aa-vehicle-model-year').value,
                            Kilometres: vehicle.querySelector('.js-aa-vehicle-kilometres').value
                        })
                    }
                })
                $personalLoanForm.my("data").AAPersonal.Vehicles = vehicleData
            }
            const hasVehicleLoan = validateInputs($('#applicationHasVehicleLoan'))
            const hasLoanFor = validateInputs($('#loanFor'))
                        
            if (aaMember && $('#applicationAAMember').val() === 'Yes') {
                aaMembershipNumber = validateAAMembershipNumber($('#aaMoneyMembershipNumber'))
                if (hasLoanFor && $('#loanFor').val() === 'debt_consolidation') {
                    const tellUsMore = validateInputs($('#tellUsConsolidate'))
                    const consolidateReason = validateConsolidateDebtReason('consolidate_debt_reason')
                    return (loanAmount && loanTerm && loanFreq && type && aaMember && aaMembershipNumber && hasLoanFor && vehicleInputs && hasVehicleLoan && tellUsMore && consolidateReason)
                } else {
                    const tellUsMore = validateInputs($('#tellUsDefault'))
                    return (loanAmount && loanTerm && loanFreq && type && aaMember && aaMembershipNumber && hasLoanFor && vehicleInputs && hasVehicleLoan && tellUsMore)
                }
                
            } else {
                if (hasLoanFor && $('#loanFor').val() === 'debt_consolidation') {
                    const tellUsMore = validateInputs($('#tellUsConsolidate'))
                    const consolidateReason = validateConsolidateDebtReason('consolidate_debt_reason')
                    return (loanAmount && loanTerm && loanFreq && type && aaMember && hasLoanFor && vehicleInputs && hasVehicleLoan && tellUsMore && consolidateReason)
                } else {
                    const tellUsMore = validateInputs($('#tellUsDefault'))
                    return (loanAmount && loanTerm && loanFreq && type && aaMember && hasLoanFor && vehicleInputs && hasVehicleLoan && tellUsMore)
                }
            }
        }
        else {
            return (loanAmount && loanTerm && loanFreq && type && year)
        }
    }

    function validateEmail(emailDOM) {
        const email = $(emailDOM).val()
        const parentElement = $(emailDOM).parent()
        if (emailRegExp.test(email)) {
            parentElement.find('.error').html('Required')
            parentElement.find('label').attr('style', '')
            parentElement.find('input').attr('style', '')
            parentElement.find('.error').attr('style', '')
            parentElement.removeClass('has-error')
            return true
        } else {
            parentElement.find('.error').html('Invalid Email Address')
            parentElement.find('label').attr('style', 'color:red')
            parentElement.find('input').attr('style', 'border-color:red')
            parentElement.find('.error').attr('style', 'display:block')
            parentElement.addClass('has-error')
            window.scrollTo(0, $(emailDOM).offset().top)  // scroll to the invalid email position
            return false
        }
    }

    function validatePhone(val) {
        let num = $(val).val();
        let $parent = $(val).parent()
        if (/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{3,6}$/.test(num)) {
            //valid name
            $parent.find('label').attr('style', '');
            $parent.find('input').attr('style', '');
            $parent.find('.error').attr('style', '');
            $parent.find('.error').html('Required');
            $parent.removeClass('has-error');
            return true;
        } else {
            //invalid name
            $parent.find('label').attr('style', 'color:red');
            $parent.find('input').attr('style', 'border-color:red');
            $parent.find('.error').attr('style', 'display:block');
            $parent.find('.error').html('Invalid phone number');
            $parent.addClass('has-error');
            return false;
        }
    }

    function validateLicense(version, Type, number) {
        if ($(Type).val() === 'No Licence') {
            $(version).parent().find('label').attr('style', '');
            $(version).parent().find('input').attr('style', '');
            $(version).parent().find('.error').attr('style', '');
            $(version).parent().find('.error').html('Required');
            $(number).parent().find('label').attr('style', '');
            $(number).parent().find('input').attr('style', '');
            $(number).parent().find('.error').attr('style', '');
            $(number).parent().find('.error').html('Required');

            $(version).attr('placeholder', 'No Driver Licence Version Needed').val('').parent().addClass('not-needed');
            $(number).attr('placeholder', 'No Driver Licence Number Needed').val('').parent().addClass('not-needed');

            return true;
        }
        let value = $(version).val();
        if (value.length === 3) {
            //valid name
            $(version).parent().find('label').attr('style', '');
            $(version).parent().find('input').attr('style', '');
            $(version).parent().find('.error').attr('style', '');
            $(version).parent().find('.error').html('Required');
            return true;
        } else {
            //invalid name
            $(version).parent().find('label').attr('style', 'color:red');
            $(version).parent().find('input').attr('style', 'border-color:red');
            $(version).parent().find('.error').attr('style', 'display:block');
            $(version).parent().find('.error').html('Invalid licence number');
            return false;
        }
    }

    function makeInputError(input) {
        $(input).parent().find('label').attr('style', 'color:red');
        $(input).parent().find('input').attr('style', 'border-color:red').addClass('js-invalid');
        $(input).parent().find('textarea').attr('style', 'border-color:red');
        $(input).parent().find('.error').attr('style', 'display:block');
        $(input).parent().addClass('has-error');
        if ($(input).parent().hasClass('bka-dropdown') || $(input).parent().hasClass('bka-dropdown-2')) {
            $(input).parent().parent().find('label').attr('style', 'color:red');
            $(input).parent().parent().find('.input').attr('style', 'border-color:red').addClass('js-invalid');
            $(input).parent().parent().find('.error').attr('style', 'display:block');
        }
    }

    function makeInputSuccess(input) {
        $(input).parent().find('label').attr('style', '');
        $(input).parent().find('input').attr('style', '').removeClass('js-invalid');
        $(input).parent().find('textarea').attr('style', '');
        $(input).parent().find('.error').attr('style', '');
        $(input).parent().removeClass('has-error');
        if ($(input).parent().hasClass('bka-dropdown') || $(input).parent().hasClass('bka-dropdown-2')) {
            $(input).parent().parent().find('label').attr('style', '');
            $(input).parent().parent().find('.input').attr('style', '').removeClass('js-invalid');
            $(input).parent().parent().find('.error').attr('style', '');
        }
    }

    function validateInputs(inputs) {
        var result = true

        for (var input of inputs) {
            if (!$(input).val() || ($(input).val() === 'Select' || $(input).val() === 'Select *' || $(input).val().toLowerCase().indexOf('select') >= 0)) {
                makeInputError(input)
                result = false
            } else {
                makeInputSuccess(input)
            }
        }
        return result
    }

    function validateAAMembershipNumber(input) {
        var result = true
        if (!$(input).val() || $(input).val().length !== 16) {
            makeInputError(input)
            result = false
        } else {
            makeInputSuccess(input)
        }
        return result
    }

    function validateDescItems(items) {
        var result = true;

        for (var item of items) {
            var itemDescInput = item.find('.js-desc-control');
            var itemInput = item.find('.js-money-control');
            var itemInput2 = item.find('.js-money-control').eq(1);

            if (!$(itemDescInput).val() && !$(itemInput).val() && !$(itemInput2).val()) {
                if (item.hasClass('js-can-be-empty')) {
                    makeInputSuccess(itemDescInput);
                    makeInputSuccess(itemInput);
                    makeInputSuccess(itemInput2);
                    result = result;
                } else {
                    validateInputs([itemDescInput, itemInput, itemInput2]);
                    result = false;
                }
            } else if ($(itemDescInput).val() && $(itemInput).val() && $(itemInput2).val()) {
                makeInputSuccess(itemDescInput);
                makeInputSuccess(itemInput);
                makeInputSuccess(itemInput2);
                result = result;
            } else {
                validateInputs([itemDescInput, itemInput, itemInput2]);
                result = false;
            }
        }
        return result;
    }

    function validateAge(ageElement) {
        // validate age string matches dd/mm/yyyy
        const ageString = $(ageElement).val()
        const ageRegex = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/
        const ageRegexValid = ageRegex.test(ageString)
        let themeName = $('#theme-name').val()
        let companyName = 'a UDC Finance'

        if (themeName === 'aamoney-theme') {
            companyName = 'an AA Money'
        }

        // check if DOB is empty or DOB is 1901 or 0001 from api data population
        if ($(ageElement).val() === '' || $(ageElement).val() === '01/01/1901' || $(ageElement).val() === '01/01/0001' || !ageRegexValid) {
            $(ageElement).parent().parent().find('label').attr('style', 'color:red');
            $(ageElement).parent().find('input').attr('style', 'border-color:red');
            $(ageElement).parent().find('.error').attr('style', 'display:block');
            $(ageElement).parent().find('.error').html('Please confirm you have entered a valid date');
            $(ageElement).parent().addClass('has-error');
            return false;
        }

        let age = calculateAge($(ageElement).val())

        //check if age is less than 18
        if (age < 18) {
            $(ageElement).parent().parent().find('label').attr('style', 'color:red');
            $(ageElement).parent().find('input').attr('style', 'border-color:red');
            $(ageElement).parent().find('.error').attr('style', 'display:block');
            $(ageElement).parent().find('.error').html('Please confirm your date of birth. You need to be at least 18 to apply for ' + companyName + ' vehicle loan.');
            $(ageElement).parent().addClass('has-error');
            return false;
        } else {
            $(ageElement).parent().parent().find('label').attr('style', '');
            $(ageElement).parent().find('input').attr('style', '');
            $(ageElement).parent().find('.error').attr('style', '');
            $(ageElement).parent().find('.error').html('');
            $(ageElement).parent().removeClass('has-error');
            return true;
        }
    }

    function calculateAge(birthday) { // birthday is a date
        var today = new Date();
        var dateParts = birthday.split("/");
        var birthDate = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    function _validateFinancialPosition() {
        var fieldsToValidate = [];
        fieldsToValidate.push($('#rentOrOwn'));

        var fieldsToValidateDesc = [];

        var newItems = $('.js-new-asset-group-list-item');
        var newItemsDesc = $('.js-new-asset-group-list-item-desc');

        newItems.each(function (index) {
            if (!$(this).parent().hasClass('temp-other-asset')) {
                fieldsToValidate.push($(this).find('.js-field-validate'));
                fieldsToValidate.push($(this).find('.js-money-control'));
            }
        });
        newItemsDesc.each(function (index) {
            fieldsToValidateDesc.push($(this));
        });

        if ($('#rentOrOwn').val() === 'Own') {
            fieldsToValidate.push($('#mortgageAssetValue'));
            fieldsToValidate.push($('#mortgageAssetBalance'));
            fieldsToValidate.push($('#mortgageAssetMonthlyPayments'));
        }
        if ($('#rentOrOwn').val() === 'Rent') {
            fieldsToValidate.push($('#mortgageRentMonthlyPayments'));
        }
        // validateDescItems(fieldsToValidateDesc)
        var validateStandardInputs = validateInputs(fieldsToValidate);
        var validateDescriptionInputs = validateDescItems(fieldsToValidateDesc);
        return validateStandardInputs && validateDescriptionInputs;
    }

    function _validateYourDetails() {
        const fieldsToValidate = []

        fieldsToValidate.push($('#title'))
        fieldsToValidate.push($('#firstName'))
        fieldsToValidate.push($('#lastName'))
        fieldsToValidate.push($('#email'))

        // TODO: We probably can improve and pass all inputs to the validateInputs, which should take care to validate phone and email based on input type
        const allValidate = validateInputs(fieldsToValidate)
        const numberValidate = validatePhone($('#mobileNumber'))
        const emailValidate = validateEmail($('#email'))

        return (allValidate && numberValidate && emailValidate)
    }

    function _validateAboutYou() {
        const fieldsToValidate = []

        fieldsToValidate.push($('#maritalStatus'))
        fieldsToValidate.push($('#driverLicenseType'))
        if ($('#driverLicenseType').val() !== 'No Licence') {
            fieldsToValidate.push($('#driverLicenseNumber'))
            fieldsToValidate.push($('#driverLicenseVersion'))
        }
        fieldsToValidate.push($('#countryOfBirth'))
        fieldsToValidate.push($('#countryOfCitizenship'))
        fieldsToValidate.push($('#applicationSubType'))
        fieldsToValidate.push($('#address'))
        fieldsToValidate.push($('#city'))
        fieldsToValidate.push($('#postcode'))
        fieldsToValidate.push($('#country'))
        fieldsToValidate.push($('#residenceType'))
        fieldsToValidate.push($('#timeAtAddressYears'))
        fieldsToValidate.push($('#timeAtAddressMonths'))
        if (parseInt($('#timeAtAddressYears').val()) < 3) {
            fieldsToValidate.push($('#previousAddress'))
            fieldsToValidate.push($('#previousCity'))
            fieldsToValidate.push($('#previousPostcode'))
            fieldsToValidate.push($('#previousCountry'))
            fieldsToValidate.push($('#previousTimeAtAddressYears'))
            fieldsToValidate.push($('#previousTimeAtAddressMonths'))
        }

        if (!jQuery('#reuseForPostalAddress').is(':checked')) {
            fieldsToValidate.push($('#postAddress'))
            fieldsToValidate.push($('#postcity'))
            fieldsToValidate.push($('#postZipCode'))
            fieldsToValidate.push($('#postcountry'))
        }

        if ($('#applicationSubType').val().toLowerCase() === 'joint') {
            fieldsToValidate.push($('#coBorrowerTitle'))
            fieldsToValidate.push($('#coborrowerfirstName'))
            fieldsToValidate.push($('#coborrowerlastName'))
            //fieldsToValidate.push($('#coborrowermobileNumber'))
            fieldsToValidate.push($('#coborroweremail'))
            fieldsToValidate.push($('#coborrowermaritalStatus'))
            fieldsToValidate.push($('#coborrowerdriverLicenseType'))
            if ($('#coborrowerdriverLicenseType').val() !== 'No Licence') {
                fieldsToValidate.push($('#coborrowerdriverLicenseNumber'))
                fieldsToValidate.push($('#coborrowerdriverLicenseVersion'))
            }
            fieldsToValidate.push($('#coborrowercountryOfBirth'))
            fieldsToValidate.push($('#coborrowercountryOfCitizenship'))
            fieldsToValidate.push($('#coborroweraddress'))
            fieldsToValidate.push($('#coborrowercity'))
            fieldsToValidate.push($('#coborrowerpostcode'))
            fieldsToValidate.push($('#coborrowercountry'))
            fieldsToValidate.push($('#coborrowerResidenceType'))
            fieldsToValidate.push($('#coborrowertimeAtAddressYears'))
            fieldsToValidate.push($('#coborrowertimeAtAddressMonths'))
            fieldsToValidate.push($('#coBorrowerPostAddress'))
            fieldsToValidate.push($('#coBorrowerPostCity'))
            fieldsToValidate.push($('#coBorrowerPostZIP'))
            fieldsToValidate.push($('#coBorrowerPostCountry'))
            if (parseInt($('#coborrowertimeAtAddressYears').val()) < 3) {
                fieldsToValidate.push($('#coborrowerPreviousAddress'))
                fieldsToValidate.push($('#coborrowerPreviousCity'))
                fieldsToValidate.push($('#coborrowerPreviousPostcode'))
                fieldsToValidate.push($('#coborrowerPreviousCountry'))
                fieldsToValidate.push($('#coborrowerPreviousTimeAtAddressYears'))
                fieldsToValidate.push($('#coborrowerPreviousTimeAtAddressMonths'))
            }

            var cbemailValidate = validateEmail($('#coborroweremail'))
            var cbnumberValidate = validatePhone($('#coborrowermobileNumber'))

            var allValidate = validateInputs(fieldsToValidate)
            var formValidate = (_isFormValid() && $resultForm.attr("valid") === "true")
            if (!formValidate) {
                $('#purchase-validate').show()
            } else {
                $('#purchase-validate').hide()
            }

            var validateLicenseNumber = validateLicense($('#driverLicenseVersion'), $('#driverLicenseType'), $('#driverLicenseNumber'))
            var cbvalidateLicenseNumber = validateLicense($('#coborrowerdriverLicenseVersion'), $('#coborrowerdriverLicenseType'), $('#coborrowerdriverLicenseNumber'))

            var dateOfBirth = validateAge($('#dateOfBirth'))
            var cbdateOfBirth = validateAge($('#coborrowerdateOfBirth'))

            return (formValidate && allValidate && cbemailValidate && cbnumberValidate
                && validateLicenseNumber && cbvalidateLicenseNumber && dateOfBirth && cbdateOfBirth)
        } else {
            var allValidate = validateInputs(fieldsToValidate)
            var formValidate = (_isFormValid() && $resultForm.attr("valid") === "true")
            if (!formValidate) {
                $('#purchase-validate').show()
            } else {
                $('#purchase-validate').hide()
            }
            var validateLicenseNumber = validateLicense($('#driverLicenseVersion'), $('#driverLicenseType'), $('#driverLicenseNumber'))
            var dateOfBirth = validateAge($('#dateOfBirth'))
            return (formValidate && allValidate && validateLicenseNumber && dateOfBirth)
        }
    }

    function _validateEmployeementDetails() {
        var fieldsToValidate = [];

        fieldsToValidate.push($('#employerName'));
        fieldsToValidate.push($('#occupation'));
        fieldsToValidate.push($('#employmentType'));
        fieldsToValidate.push($('#timeInJobYears'));

        if ($('#timeInJobYears').val().replace('years', '').replace('year', '').trim() < 3) {
            const hasPreviousEmployerPrimary = $('#noPreviousEmployer').prop('checked');
            if (!hasPreviousEmployerPrimary) {
                fieldsToValidate.push($('#previousEmployerName'));
                fieldsToValidate.push($('#previousOccupation'));
                fieldsToValidate.push($('#previousEmploymentType'));
                fieldsToValidate.push($('#previousTimeInJobYears'));
            }
        }

        fieldsToValidate.push($('#paymentDuration'));
        fieldsToValidate.push($('#Income'));
        fieldsToValidate.push($('#ddlotherIncome'));
        if ($('#ddlotherIncome').val().toLowerCase() == "yes") {
            fieldsToValidate.push($('#Amount'));
            fieldsToValidate.push($('#frequency'));
            fieldsToValidate.push($('#incomeType'));

            var moreThenOne = $('#other-income-attach').find('.row').length - 1;
            if (moreThenOne > 0) {
                for (var i = 1; i <= moreThenOne; i++) {
                    fieldsToValidate.push($('#Amount' + [i + 1]));
                    fieldsToValidate.push($('#frequency' + [i + 1]));
                    fieldsToValidate.push($('#incomeType' + [i + 1]));
                }
            }
        }

        fieldsToValidate.push($('#incomeDecreaseExpected'));
        if ($('#incomeDecreaseExpected').val().toLowerCase() === 'yes') {
            fieldsToValidate.push($('#incomeDecreaseDetails'));
        }

        if ($('#applicationSubType').val().toLowerCase() === 'joint') {
            fieldsToValidate.push($('#coBorrowerEmployerName'));
            fieldsToValidate.push($('#coBorrowerOccupation'));
            fieldsToValidate.push($('#coBorrowerEmploymentType'));
            fieldsToValidate.push($('#coBorrowerTimeInJobYears'));
            
            if ($('#coBorrowerTimeInJobYears').val().replace('years', '').replace('year', '').trim() < 3) {
                const hasPreviousEmployerCoborrower = $('#noCoborrowerPreviousEmployer').prop('checked');
                if (!hasPreviousEmployerCoborrower) {
                    fieldsToValidate.push($('#coBorrowerPreviousEmployerName'));
                    fieldsToValidate.push($('#coBorrowerPreviousOccupation'));
                    fieldsToValidate.push($('#coBorrowerPreviousEmploymentType'));
                    fieldsToValidate.push($('#coBorrowerPreviousTimeInJobYears'));
                }
            }

            fieldsToValidate.push($('#coborrowerpaymentDuration'));
            fieldsToValidate.push($('#SpousalIncome'));
            fieldsToValidate.push($('#coborrowerddlotherIncome'));
            if ($('#coborrowerddlotherIncome').val().toLowerCase() == "yes") {
                fieldsToValidate.push($('#coborrowerAmount'));
                fieldsToValidate.push($('#coborrowerfrequency'));
                fieldsToValidate.push($('#coborrowerIncomeType'));

                var cbCoreThenOne = $('#other-income-attach-co-borrower').find('.row').length - 1;
                if (cbCoreThenOne > 0) {
                    for (var i = 1; i <= cbCoreThenOne; i++) {
                        fieldsToValidate.push($('#coborrowerAmount' + [i + 1]));
                        fieldsToValidate.push($('#coborrowerfrequency' + [i + 1]));
                        fieldsToValidate.push($('#coborrowerIncomeType' + [i + 1]));
                    }
                }
            }
        }

        return (validateInputs(fieldsToValidate));
    }

    function _getOtherIncomes() {
        var arr = [];
        var getPrimary = getPrimaryOtherIncome();
        var getCoborrower = getCoborrowerOtherIncome();
        if (getPrimary.length > 0) {
            getPrimary.each(function (i) {
                arr.push(i);
            });
        }
        if (getCoborrower.length > 0) {
            getCoborrower.each(function (j) {
                arr.push(j);
            })
        }

        return arr;
    }

    function getPrimaryOtherIncome() {
        var $otherIncomeArr = $("#other-income-attach").find('.row');
        var otherIncomes = [];
        $otherIncomeArr.each(function (i) {
            let count = i + 1;
            if (parseFloat(formatPrice($(this).find('#Amount' + (count === 1 ? "" : count)).val())) > 0) {
                otherIncomes.push({
                    amount: parseFloat(formatPrice($(this).find('#Amount' + (count === 1 ? "" : count)).val())),
                    frequency: $(this).find('#frequency' + (count === 1 ? "" : count)).val(),
                    description: $(this).find('#Description' + (count === 1 ? "" : count)).val(),
                    incomeType: $(this).find('#incomeType' + (count === 1 ? "" : count)).val(),
                    isBorrower: false
                });
            }
        })
        return otherIncomes;
    }

    function getCoborrowerOtherIncome() {
        var $otherIncomeArr = $("#other-income-attach-co-borrower").find('.row');
        var otherIncomes = [];
        $otherIncomeArr.each(function (i) {
            let count = i + 1;
            if (parseFloat(formatPrice($(this).find('#coborrowerAmount' + (count === 1 ? "" : count)).val())) > 0) {
                otherIncomes.push({
                    amount: parseFloat(formatPrice($(this).find('#coborrowerAmount' + (count === 1 ? "" : count)).val())),
                    frequency: $(this).find('#coborrowerfrequency' + (count === 1 ? "" : count)).val(),
                    description: $(this).find('#coborrowerDescription' + (count === 1 ? "" : count)).val(),
                    incomeType: $(this).find('#coborrowerIncomeType' + (count === 1 ? "" : count)).val(),
                    isBorrower: true
                });
            }
        })
        return otherIncomes;
    }

    function _getAssets() {
        var $assets = $('.js-asset-group').find('.assets-group-list-item');
        var assetsList = [];
        $assets.each(function () {
            var $this = $(this);
            if (!$this.hasClass('title') && $this.find('.assets-item-title').find('input').length) {
                assetsList.push({
                    assetType: $this.find('.assets-item-title').find('input').parent().find('.option.selected').attr('asset-value'),
                    Amount: parseFloat(formatPrice($this.find('.assets-item-input').find('input').val()))
                });
            }
        });
        return assetsList;
    }

    function _getOtherFinancialCommits() {
        var $Commits = $('.js-commitments-group').find('.assets-group-list-item');
        var commitList = [];
        $Commits.each(function () {
            var $this = $(this);
            if ($this.find('.assets-item-title').find('.fc-description').length) {
                commitList.push({
                    Description: $this.find('.assets-item-title').find('.fc-description').val(),
                    Amount: parseFloat(formatPrice($this.find('.assets-item-input').find('.other-limit').val())),
                    MonthlyPayments: parseFloat(formatPrice($this.find('.assets-item-input').find('.other-monthly-payments').val()))
                });
            }
        });
        return commitList;
    }

    function _getOtherRecurringExpenses() {
        var $expenditure = $('.js-household-expenditure-group').find('.assets-group-list-item');
        var expenditureList = [];
        $expenditure.each(function () {
            var $this = $(this);
            if ($this.find('.assets-item-title').find('input').length) {
                expenditureList.push({
                    ExpenseType: $this.find('.assets-item-title').find('input').parent().find('.option.selected').attr('expense-value'),
                    Amount: parseFloat(formatPrice($this.find('.assets-item-input').find('.other-monthly-expenditure').val())),
                    Frequency: formatPrice($this.find('.assets-item-input').find('.other-monthly-expenditure-freq').val()),
                });
            }
        });
        return expenditureList;
    }

    function _getOtherAssetAmountSum() {
        var sum = 0;
        $(".other-limit").each(function () {
            sum += GetSanitizedValue(this);
        });

        return parseFloat(sum);
    }

    function _getOtherMonthlyPaymentSum() {
        var sum = 0;
        $(".other-monthly-payments").each(function () {
            sum += GetSanitizedValue(this);
        });
        return parseFloat(sum);
    }

    function checkSumUpFrequency(el) {
        let thisElemParent = $(el).closest('.assets-item.assets-item-input.double')
        let frequencyInput = $(thisElemParent).prev('.assets-item.assets-item-input.double').find('input.js-field-validate')
        return $(frequencyInput).val()
    }

    function GetSanitizedValue(el) {
        let sanitized = CurrencyFormatted($(el).val())

        // check frequency if it is necessary
        let sanitizedFrequency = checkSumUpFrequency(el)

        switch (sanitizedFrequency) {
            case 'Weekly':
                sanitizedNumber = parseFloat(sanitized.replace('$ ', '').replace(',', '')) * 52 / 12
                return sanitizedNumber

            case 'Fortnightly':
                sanitizedNumber = parseFloat(sanitized.replace('$ ', '').replace(',', '')) * 26 / 12
                return sanitizedNumber

            default:
                if (sanitized === "") return 0
                return parseFloat(sanitized.replace('$ ', '').replace(',', ''))
        }
    }

    function _copyAddress() {
        if ($('.js-personal-reuse-postal').prop('checked')) {
            CopyAddressDetails($('.js-personal-reuse-postal'), '.personal-postal-address');
        }
        if ($('.js-borrower-reuse-postal').prop('checked')) {
            CopyAddressDetails($('.js-borrower-reuse-postal'), '.borrower-postal-address');
        }
        if ($('#sameAddress').val() == 'Yes') {
            copyAllAddressDetails();
        }
        var countryOfCitizenship = getotherCountries('.personal-citizen-country');
        var coborrowerCountryOfCitizenship = getotherCountries('.coborrower-citizen-country');
        var model = $personalLoanForm.my("data");
        if (countryOfCitizenship) {
            var main = $('#countryOfCitizenship').parent().find('.option.selected').attr('country-value')
            var val = main + ',' + countryOfCitizenship;
            var list = val.split(',').filter((x, i, a) => a.indexOf(x) == i);
            model.CountryOfCitizenship = list.join();
        }
        if (coborrowerCountryOfCitizenship) {
            var bor = $('#coborrowercountryOfCitizenship').parent().find('.option.selected').attr('country-value');
            var val = bor + ',' + coborrowerCountryOfCitizenship;
            var list = val.split(',').filter((x, i, a) => a.indexOf(x) == i);
            model.Personal.CoBorrowerCountryOfCitizenship = list.join();
        }
    }

    function getotherCountries(elClass) {
        var lst = [];
        $(elClass).each(function () {
            var value = $(this).parent().find('.option.selected').attr('country-value');
            if (value)
                lst.push(value);
        });
        return lst.join();
    }

    function updateIncomeDecreaseText() {
        $('#heading-text').text('Is your income likely to decrease over the next 12 months');
        if ($('#applicationSubType').val().toLowerCase() === 'joint') {
            $('#heading-text').text('Is your income or your spouse/partner’s income likely to decrease over the next 12 months');
        }
    }

    function _bindForm(data) {

        // modify itf it is a random DOB from system 
        if (data.DOB === '1901-01-01T00:00:00' || data.DOB === '0001-01-01T00:00:00') {
            data.DOB = ""
        }

        try {
            if (!!data.AAPersonal) {
                $('#loanFor').val(data.AAPersonal.PersonalLoanType)
                $('#tellUsDefault').val(data.AAPersonal.PersonalLoanReason)
                $('#tellUsConsolidate').val(data.AAPersonal.PersonalLoanReason)

                $('#loanFor').parent().find('.radio-input').each(function () {
                    if ($(this).val() == data.AAPersonal.PersonalLoanType) {
                        $(this).prop( "checked", true )
                    }
                })

                if (data.AAPersonal.PersonalLoanType === 'debt_consolidation') {
                    $('.js-loan-for-consolidate').removeClass('hidden')
                } else {
                    $('.js-loan-for-default').removeClass('hidden')
                }

                if (data.AAPersonal.LoanForVehicle) {
                    $('#applicationHasVehicleLoan').val('Yes')
                    $('#applicationHasVehicleLoan').parent().find('.radio-input').each(function () {
                        if ($(this).val() == 'Yes') {
                            $(this).prop( "checked", true )
                        }
                    })
                } else {
                    $('#applicationHasVehicleLoan').val('No')
                    $('#applicationHasVehicleLoan').parent().find('.radio-input').each(function () {
                        if ($(this).val() == 'No') {
                            $(this).prop( "checked", true )
                        }
                    })
                }

                if (data.AAPersonal.DebtConsolidationReason.length > 0) {
                    const consolidateDebtReasonCheckboxes = $('#consolidateDebtReasonCheckboxes').find('.checkbox__input')
                    for (i = 0; i < data.AAPersonal.DebtConsolidationReason.length; i++) {
                        consolidateDebtReasonCheckboxes.each(function () {
                            if ($(this).val() == data.AAPersonal.DebtConsolidationReason[i]) {
                                $(this).prop( "checked", true )
                            }
                        })
                    }
                }

                if (data.AAPersonal.Vehicles.length > 0) {
                    var html = $('.js-add-aa-personal-vehicle-template').html()
                    for (i = 0; i < data.AAPersonal.Vehicles.length; i++) {
                        if (i === 0) {
                            $('.js-aa-personal-vehicle-wrapper--first').find('.js-aa-vehicle-registration').val(data.AAPersonal.Vehicles[i].Registration)
                            $('.js-aa-personal-vehicle-wrapper--first').find('.js-aa-vehicle-make').val(data.AAPersonal.Vehicles[i].Make)
                            $('.js-aa-personal-vehicle-wrapper--first').find('.js-aa-vehicle-model').val(data.AAPersonal.Vehicles[i].Model)
                            $('.js-aa-personal-vehicle-wrapper--first').find('.js-aa-vehicle-model-year').val(data.AAPersonal.Vehicles[i].ModelYear)
                            $('.js-aa-personal-vehicle-wrapper--first').find('.js-aa-vehicle-kilometres').val(data.AAPersonal.Vehicles[i].Kilometres)
                        } else {
                            var vehicleRow = $('.js-add-aa-personal-vehicle-template .js-aa-personal-vehicle-wrapper').clone()
                            $(vehicleRow).find('.js-aa-vehicle-registration').val(data.AAPersonal.Vehicles[i].Registration)
                            $(vehicleRow).find('.js-aa-vehicle-make').val(data.AAPersonal.Vehicles[i].Make)
                            $(vehicleRow).find('.js-aa-vehicle-model').val(data.AAPersonal.Vehicles[i].Model)
                            $(vehicleRow).find('.js-aa-vehicle-model-year').val(data.AAPersonal.Vehicles[i].ModelYear)
                            $(vehicleRow).find('.js-aa-vehicle-kilometres').val(data.AAPersonal.Vehicles[i].Kilometres)
                            $('.js-aa-personal-vehicles-container').prepend(vehicleRow)
                        }
                    }
                }
            }

            if (data.Personal.NoPreviousEmployer) {
                $('#noPreviousEmployer').prop('checked', true).trigger('change')
            }
            if (data.Personal.NoCoBorrowerPreviousEmployer) {
                $('#noCoborrowerPreviousEmployer').prop('checked', true).trigger('change')
            }
        
            if (data.AaMoneyMembershipNumber != '') {
                $('#applicationAAMember').val('Yes')
                $('#aaMoneyMembershipNumber').val(data.AaMoneyMembershipNumber)
                $('#applicationAAMember').parent().find('.radio-input').each(function () {
                    if ($(this).val() == 'Yes') {
                        $(this).prop( "checked", true )
                    }
                });
            } else {
                $('#applicationAAMember').val('No')
                $('#applicationAAMember').parent().find('.radio-input').each(function () {
                    if ($(this).val() == 'No') {
                        $(this).prop( "checked", true )
                    }
                })
            }
            
            data.PurchaseItems = data.PurchaseItems[0]

            // Get standarised descriptions for the dropdowns as they can come through as both description or codes
            data.DriverLicenceType = personalLoan.helpers.getDrivingLicenceTypeDescription(data.DriverLicenceType)

            if (!!data.Personal) {
                data.Personal.CoBorrowerDriverLicenceType = personalLoan.helpers.getDrivingLicenceTypeDescription(data.Personal.CoBorrowerDriverLicenceType)
                data.Personal.EmploymentType = personalLoan.helpers.getEmploymentTypeDescription(data.Personal.EmploymentType)
                data.Personal.PreviousEmploymentType = personalLoan.helpers.getEmploymentTypeDescription(data.Personal.PreviousEmploymentType)
                data.Personal.CoBorrowerPreviousEmploymentType = personalLoan.helpers.getEmploymentTypeDescription(data.Personal.CoBorrowerPreviousEmploymentType)
                data.Personal.CoBorrowerEmploymentType = personalLoan.helpers.getEmploymentTypeDescription(data.Personal.CoBorrowerEmploymentType)
                data.Personal.TakeHomePayFrequency = personalLoan.helpers.getPaymentFrequencyDescription(data.Personal.TakeHomePayFrequency)
                data.Personal.SpousePartnerFrequency = personalLoan.helpers.getPaymentFrequencyDescription(data.Personal.SpousePartnerFrequency)
            }

            data.PaymentFrequency = personalLoan.helpers.getPaymentFrequencyDescription(data.PaymentFrequency)

            data.OtherIncomes.forEach(otherIncome => {
                otherIncome.Frequency = personalLoan.helpers.getPaymentFrequencyDescription(otherIncome.Frequency)
            })

            if (data.Country !== '') {
                $('#country').parent().find('.option').each(function () {
                    if (this.innerText == data.Country) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
            }

            if (data.CountryOfBirth !== '') {
                $('#countryOfBirth').parent().find('.option').each(function () {
                    if (this.innerText == data.CountryOfBirth) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
            }

            if (data.PreviousCountry !== '') {
                $('#previousCountry').parent().find('.option').each(function () {
                    if (this.innerText == data.PreviousCountry) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
            }

            if (data.PostCountry !== '') {
                $('#postcountry').parent().find('.option').each(function () {
                    if (this.innerText == data.PostCountry) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
            }

            if (data.CountryOfCitizenship !== '') {
                $('#countryOfCitizenship').parent().find('.option').each(function () {
                    if (this.innerText == data.CountryOfCitizenship) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
            }
            if (data.DependantsNumber !== '') {
                $('#dependants').val(data.DependantsNumber)
            }
            $personalLoanForm.my("data", data);

            if (!!data.PurchaseItems) {
                $('#loanAmount').val(data.PurchaseItems.PurchasePrice);
                $('#deposit').val(data.PurchaseItems.CashDeposit);
                $('#make').val(data.PurchaseItems.Make);
                $('#year').val(data.PurchaseItems.Year);
                $('#model').val(data.PurchaseItems.Model);
                $('#itemType').val(data.PurchaseItems.ItemType)
                if (data.PurchaseItems.ItemType) {
                    $($('.js-purchase').find("[data-good-type=" + data.PurchaseItems.ItemType + "]")[0]).addClass('active');
                }
                _setValues(data.PurchaseItems)
                $goodsType.find('#mainForm').show();
            }
            _addPurchaseItem();

            $('.js-personal-reuse-postal').prop('checked', data.ReuseForPostalAddress)
            if (!!data.ReuseForPostalAddress) {
                $('.personal-postal-address').addClass('hidden');
            } else {
                $('.personal-postal-address').removeClass('hidden');
                $('.js-personal-reuse-postal').parent().find('.postalCheckbox').removeClass('checked')
            }

            personalLoan.app.startLoanApplication();

            if (data.DependantsNumber !== '') {
                $('#dependants').val(data.DependantsNumber)
            }

            if (data.Title !== '') {
                $('#title').parent().find('.option').each(function () {
                    if ($(this).text() == data.Title) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
            }

            if (!!data.MaritalStatus) {
                const maritalStatusData = window.Data.maritalStatus
                let populatedMaritalStatus = maritalStatusData.find(status =>
                    status.code.trim().toLowerCase() === data.MaritalStatus.trim().toLowerCase() ||
                    status.label.trim().toLowerCase() === data.MaritalStatus.trim().toLowerCase())
                $('#maritalStatus').parent().find('.option').each(function () {
                    if ($(this).text().trim().toLowerCase() === populatedMaritalStatus.label.trim().toLowerCase()) {
                        $(this).addClass('selected').trigger('click')
                    }
                })
            }

            if (!!data.Personal && !!data.Personal.CoBorrowerMaritalStatus) {
                const maritalStatusData = window.Data.maritalStatus
                let populatedMaritalStatus = maritalStatusData.find(status =>
                    status.code.trim().toLowerCase() === data.Personal.CoBorrowerMaritalStatus.trim().toLowerCase() ||
                    status.label.trim().toLowerCase() === data.Personal.CoBorrowerMaritalStatus.trim().toLowerCase())
                $('#coborrowermaritalStatus').parent().find('.option').each(function () {
                    if ($(this).text().trim().toLowerCase() === populatedMaritalStatus.label.trim().toLowerCase()) {
                        $(this).addClass('selected').trigger('click')
                    }
                })
            }

            if (data.Country !== '') {
                $('#country').parent().find('.option').each(function () {
                    if ($(this).attr('country-value') == data.Country) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
            }

            if (data.ResidenceType !== '') {
                $('#residenceType').parent().find('.option').each(function () {
                    if ($(this).attr('residence-type-value') == data.ResidenceType) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
                if (isAAMoneyDealer) {
                    if (data.ResidenceType.toLowerCase() === 'living with parents') {
                        $('#rentOwnQ').addClass('hidden')
                        $('#rentOrOwn').val('Neither')
                    }
                }
            }

            if (data.PreviousCountry !== '') {
                $('#previousCountry').parent().find('.option').each(function () {
                    if ($(this).attr('country-value') == data.PreviousCountry) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
            }

            if (data.PostCountry !== '') {
                $('#postcountry').parent().find('.option').each(function () {
                    if ($(this).attr('country-value') == data.PostCountry) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
            }

            if (data.CountryOfBirth !== '') {
                $('#countryOfBirth').parent().find('.option').each(function () {
                    if ($(this).attr('country-value') == data.CountryOfBirth) {
                        $(this).addClass('selected').trigger('click');
                    }
                });
            }

            if (data.CountryOfCitizenship !== '') {
                var cont = null
                if (data.CountryOfCitizenship) {
                    cont = data.CountryOfCitizenship.split(',')
                }

                var countryList = $('#countryOfCitizenship + .options').children()

                var newCont = []

                if (cont && cont.length > 0) {
                    for (var j = 0; j < cont.length; j++) {
                        for (var i = 0; i < countryList.length; i++) {
                            if ($(countryList[1]).text().toLowerCase().trim() === cont[j].toLowerCase().trim() || $(countryList[i]).attr('country-value').toLowerCase().trim() === cont[j].toLowerCase().trim()) {
                                newCont.push(($(countryList[i]).attr('country-value')))
                            }
                        }
                    }
                }

                $('#countryOfCitizenship').parent().find('.option').each(function () {
                    if ($(this).attr('country-value') == newCont[0]) {
                        $(this).addClass('selected').trigger('click');
                    }
                });

                if (newCont.length >= 2) {
                    newCont.each(function (i) {
                        if (i !== newCont[0]) {
                            var html = $('#countryOfCitizenship').closest('.form-wrapper').find('.js-citizenship-template').html();
                            $('#countryOfCitizenship').closest('.form-wrapper').find('.js-citizenship-wrapper').append(html);
                        }
                    });
                    for (var j = 1; j < newCont.length; j++) {
                        $($('.js-citizenship-wrapper').find('.personal-citizen-country')[j - 1]).parent().find('.option').each(function () {
                            if ($(this).attr('country-value') == newCont[j]) {
                                $(this).addClass('selected').trigger('click');
                            }
                        });
                    }
                }
            }

            if (data.Personal) {
                $('.js-borrower-reuse-postal').prop('checked', data.Personal.CoBorrowerReuseForPostalAddress)
                if (data.Personal.CoBorrowerReuseForPostalAddress) {
                    $('.borrower-postal-address').addClass('hidden');
                } else {
                    $('.borrower-postal-address').removeClass('hidden');
                    $('.js-borrower-reuse-postal').parent().find('.postalCheckbox').removeClass('checked')
                }

                if (!!data.Personal.Occupation) {
                    $('#occupation').parent().find('.option').each(function () {
                        if ($(this).attr('occupation-value') == data.Personal.Occupation) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }

                if (data.Personal.TimeInThisJobYears != undefined && data.Personal.TimeInThisJobYears != '') {
                    $('#timeInJobYears').parent().find('.option').each(function () {
                        if ($(this).text().replace('years', '').replace('year', '').trim() == data.Personal.TimeInThisJobYears) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }

                if (!!data.Personal.PreviousOccupation) {
                    $('#previousOccupation').parent().find('.option').each(function () {
                        if ($(this).attr('occupation-value') == data.Personal.PreviousOccupation) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }

                if (data.Personal.PreviousTimeInThisJobYears != undefined && data.Personal.PreviousTimeInThisJobYears != '') {
                    $('#previousTimeInJobYears').parent().find('.option').each(function () {
                        if ($(this).text().replace('years', '').replace('year', '').trim() == data.Personal.PreviousTimeInThisJobYears) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }

                if (data.Personal.CoBorrowerNZResident) {
                    $('#coborrowernzResident').val('Yes').trigger('change');
                    $('#coborrowernzResident').parent().find('.radio-input').each(function () {
                        if ($(this).val() == 'Yes') {
                            $(this).prop( "checked", true )
                        }
                    })
                } else if (!data.Personal.CoBorrowerNZResident) {
                    $('#coborrowernzResident').val('No').trigger('change');
                    $('#coborrowernzResident').parent().find('.radio-input').each(function () {
                        if ($(this).val() == 'No') {
                            $(this).prop( "checked", true )
                        }
                    })
                }
            }

            window.setTimeout(function () {
                $('#ddlotherIncome').trigger('change');
                if ($('#ddlotherIncome').val() === 'Yes' ) {
                    $('#ddlotherIncome').parent().find('.radio-input').each(function () {
                        if ($(this).val() == 'Yes') {
                            $(this).prop( "checked", true )
                        }
                    })
                } else if ($('#ddlotherIncome').val() === 'No') {
                    $('#ddlotherIncome').parent().find('.radio-input').each(function () {
                        if ($(this).val() == 'No') {
                            $(this).prop( "checked", true )
                        }
                    })
                }
                $('#coborrowerddlotherIncome').trigger('change');
                if ($('#coborrowerddlotherIncome').val() === 'Yes' ) {
                    $('#coborrowerddlotherIncome').parent().find('.radio-input').each(function () {
                        if ($(this).val() == 'Yes') {
                            $(this).prop( "checked", true )
                        }
                    })
                } else if ($('#coborrowerddlotherIncome').val() === 'No') {
                    $('#coborrowerddlotherIncome').parent().find('.radio-input').each(function () {
                        if ($(this).val() == 'No') {
                            $(this).prop( "checked", true )
                        }
                    })
                }
                
                if ($('#incomeDecreaseExpected').val() === 'Yes' || data.Personal.IncomeDecreaseExpected === true) {
                    $('#incomeDecreaseExpected').trigger('change')
                    $('#incomeDecreaseExpected').parent().find('.radio-input').each(function () {
                        if ($(this).val() == 'Yes') {
                            $(this).prop( "checked", true )
                        }
                    })
                } else if ($('#incomeDecreaseExpected').val() === 'No' || data.Personal.IncomeDecreaseExpected === false) {
                    $('#incomeDecreaseExpected').parent().find('.radio-input').each(function () {
                        if ($(this).val() == 'No') {
                            $(this).prop( "checked", true )
                        }
                    })
                }
            }, 0);

            $('#nzResident').val(data.NZResident ? 'Yes' : 'No').trigger('change');
            if ($('#nzResident').val() === 'Yes') {
                $('#nzResident').parent().find('.radio-input').each(function () {
                    if ($(this).val() == 'Yes') {
                        $(this).prop( "checked", true )
                    }
                })
            } else if ($('#nzResident').val() === 'No') {
                $('#nzResident').parent().find('.radio-input').each(function () {
                    if ($(this).val() == 'No') {
                        $(this).prop( "checked", true )
                    }
                })
            }

            $('#applicationSubType').val(data.IsJoint ? 'Joint' : 'Single').trigger('change');
            if ($('#applicationSubType').val() === 'Single') {
                $('#applicationSubType').parent().find('.radio-input').each(function () {
                    if ($(this).val() == 'Single') {
                        $(this).prop( "checked", true )
                    }
                })
            } else if ($('#applicationSubType').val() === 'Joint') {
                $('#applicationSubType').parent().find('.radio-input').each(function () {
                    if ($(this).val() == 'Joint') {
                        $(this).prop( "checked", true )
                    }
                })
            }
            
            if (data.Personal.CoBorrowerAddress === data.Address) {
                $('#sameAddress').val() === 'Yes'
                $('#sameAddressYes').prop( "checked", true )
            } else {
                $('#sameAddress').val() === 'No'
                $('#sameAddressNo').prop( "checked", true )
            }

            if (data.Personal) {
                $('#Income').val(data.Personal.Income)
                if (data.IsJoint) {
                    $('#SpousalIncome').val(data.Personal.SpousePartnerIncome)
                }
                else {
                    $('#SpousalIncomeSingle').val(data.Personal.SpousePartnerIncome)
                }

                $('#incomeDecreaseExpected').val(data.Personal.IncomeDecreaseExpected ? 'Yes' : 'No').trigger('change');
                $('#incomeDecreaseDetails').val(data.Personal.IncomeDecreaseDetails)
            }

            $('#timeAtAddressYears').trigger('change');

            if (data.OtherFinancialCommitments && data.OtherFinancialCommitments.length > 0) {
                $('#fcDescription').val(data.OtherFinancialCommitments[0].Description);
                $('#fcAmount').val(data.OtherFinancialCommitments[0].Amount);
                $('#fcMonthlyPayments').val(data.OtherFinancialCommitments[0].MonthlyPayments);
                if (data.OtherFinancialCommitments.length > 1) {
                    for (var i = 0; i < data.OtherFinancialCommitments.length; i++) {
                        $('.js-commitments-group').append('<div class="assets-group-list-item"> <div class="assets-item assets-item-title new-assets-field"> <div class="icon"> <img class="img-fluid" src="/resources/images/icons/ellipsis-add-new.svg" alt=""> </div> <div class="form-group"> <label class="sr-only" for="">Value</label> <input type="text" placeholder="Description" maxlength="100" class="fc-description" value="' + data.OtherFinancialCommitments[i].Description + '" /> </div> </div> <div class="assets-item assets-item-input double"> <div class="form-group"> <label for="">Limit</label> <input type="text" value="' + data.OtherFinancialCommitments[i].Amount + '" placeholder="0" class="other-limit js-amount-validate js-money-control" /> </div> </div> <div class="assets-item assets-item-input double"> <div class="form-group"> <label for="">Monthly payments</label> <input type="text" value="' + data.OtherFinancialCommitments[i].MonthlyPayments + '" placeholder="0" class="sum-up other-monthly-payments js-money-control" /> </div> </div> </div>')
                    }
                    $('.js-amount-validate').on('change', function () {
                        _validateAmount($(this));
                    });
                    initSumup(".sum-up", ".assets-item-total");

                    // format money control when it is being added
                    var moneyControlElem = $('.js-money-control')
                    formatMoneyControl(moneyControlElem)
                }
            }
            
            if (data.OtherIncomes.length > 0) {
                //var primaryOtherIncome = data.OtherIncomes.fil
                var primaryOtherIncome = data.OtherIncomes.filter(obj => { return obj.IsBorrower === false; });

                if (primaryOtherIncome.length > 0) {
                    var incomeType = personalLoan.helpers.getOtherIncomeDescription(primaryOtherIncome[0].IncomeType)

                    $('#Amount').val(primaryOtherIncome[0].Amount);
                    $('#frequency').val(primaryOtherIncome[0].Frequency);
                    $('#incomeType').val(incomeType);
                    $('.form-wrapper-other-income').show();

                    if (primaryOtherIncome.length > 1) {
                        for (var i = 1; i < primaryOtherIncome.length; i++) {
                            var count = $('#other-income-attach').find('.row').length + 1;
                            var options = $('.incomeType-options').html();
                            var incomeType = personalLoan.helpers.getOtherIncomeDescription(primaryOtherIncome[i].IncomeType)

                            var html = `
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>Amount</label>
                                    <input type="text" id="Amount${count}" value="${primaryOtherIncome[i].Amount}" class="js-field-validate js-money-control">
                                    <div class="error">Required</div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>Frequency</label>
                                    <div class="bka-dropdown">
                                        <input type="text" id="frequency${count}" value="${primaryOtherIncome[i].Frequency}" disabled="disabled" placeholder="Select one option" class="js-field-validate">
                                        <div class="options">
                                            <div class="option">Weekly</div>
                                            <div class="option">Fortnightly</div>
                                            <div class="option">Monthly</div>
                                        </div>
                                    </div>
                                    <div class="error">Required</div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>Description</label>
                                    <div class="bka-dropdown">
                                        <input type="text" id="incomeType${count}" readonly value="${incomeType}" placeholder="Select one option" class="js-field-validate">
                                        <div class="options">${options}</div>
                                    </div>
                                    <div class="error">Required</div>
                                </div>
                            </div>
                        </div>`;

                            $('#other-income-attach').append(html);
                        }
                        initJsFeildValidations();
                    }
                }

                var borrowerOtherIncome = data.OtherIncomes.filter(obj => { return obj.IsBorrower === true; });
                if (borrowerOtherIncome.length > 0) {
                    var incomeType = personalLoan.helpers.getOtherIncomeDescription(borrowerOtherIncome[0].IncomeType)

                    $('#coborrowerAmount').val(borrowerOtherIncome[0].Amount);
                    $('#coborrowerfrequency').val(borrowerOtherIncome[0].Frequency);
                    $('#coborrowerIncomeType').val(incomeType);
                    $('.form-wrapper-co-other-income').show();

                    if (borrowerOtherIncome.length > 1) {
                        for (var i = 1; i < borrowerOtherIncome.length; i++) {
                            var count = $('#other-income-attach-co-borrower').find('.row').length + 1;
                            var options = $('.incomeType-options').html();
                            var incomeType = personalLoan.helpers.getOtherIncomeDescription(borrowerOtherIncome[i].IncomeType)

                            var html = `
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>Amount</label>
                                    <input type="text" id="coborrowerAmount${count}" value="${borrowerOtherIncome[i].Amount}" class="js-field-validate js-money-control">
                                    <div class="error">Required</div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>Frequency</label>
                                    <div class="bka-dropdown">
                                        <input type="text" id="coborrowerfrequency${count}" value="${borrowerOtherIncome[i].Frequency}" disabled="disabled" placeholder="Select one option" class="js-field-validate">
                                        <div class="options hidden">
                                            <div class="option">Weekly</div>
                                            <div class="option">Fortnightly</div>
                                            <div class="option">Monthly</div>
                                        </div>
                                    </div>
                                    <div class="error">Required</div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label>Description</label>
                                    <div class="bka-dropdown">
                                        <input type="text" id="coborrowerIncomeType${count}" readonly value="${incomeType}" placeholder="Select one option" class="js-field-validate">
                                        <div class="options">${options}</div>
                                    </div>
                                    <div class="error">Required</div>
                                </div>
                            </div>
                        </div>`;
                            $('#other-income-attach-co-borrower').append(html);
                        }
                        initJsFeildValidations();
                    }
                }
                var moneyControlElem = $('.js-money-control')
                formatMoneyControl(moneyControlElem)
            }

            
            if (data.IsJoint) {
                $('#coborrowernzResident').val(data.Personal.CoBorrowerNZResident ? 'Yes' : 'No').trigger('change');
                if (data.DependantsNumber !== '') {
                    $('#coborrowerdependants').val(data.Personal.CoBorrowerDependantsNumber)
                }

                if (data.Personal.CoBorrowerTitle !== '') {
                    $('#coBorrowerTitle').parent().find('.option').each(function () {
                        if ($(this).text() == data.Personal.CoBorrowerTitle) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }

                if (data.Personal.CoBorrowerCountry !== '') {
                    $('#coborrowercountry').parent().find('.option').each(function () {
                        if ($(this).attr('country-value') == data.Personal.CoBorrowerCountry) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }
                if (data.Personal.CoBorrowerResidenceType !== '') {
                    $('#coborrowerResidenceType').parent().find('.option').each(function () {
                        if ($(this).attr('residence-type-value') == data.Personal.CoBorrowerResidenceType) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }
                if (data.Personal.CoBorrowerPreviousCountry !== '') {
                    $('#coborrowerPreviousCountry').parent().find('.option').each(function () {
                        if ($(this).attr('country-value') == data.Personal.CoBorrowerPreviousCountry) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }
                if (data.Personal.CoBorrowerPostCountry !== '') {
                    $('#coBorrowerPostCountry').parent().find('.option').each(function () {
                        if ($(this).attr('country-value') == data.Personal.CoBorrowerPostCountry) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }
                if (data.Personal.CoBorrowerCountryOfBirth !== '') {
                    $('#coborrowercountryOfBirth').parent().find('.option').each(function () {
                        if ($(this).attr('country-value') == data.Personal.CoBorrowerCountryOfBirth) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }
                if (data.Personal.CoBorrowerCountryOfCitizenship !== '') {
                    var cont = data.Personal.CoBorrowerCountryOfCitizenship.split(',');
                    $('#coborrowercountryOfCitizenship').parent().find('.option').each(function () {
                        if ($(this).attr('country-value') == cont[0]) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                    if (cont.length > 2) {
                        cont.each(function (i) {
                            if (i !== cont[0]) {
                                var html = $('#coborrowercountryOfCitizenship').closest('.form-wrapper').find('.js-citizenship-template').html();
                                $('#coborrowercountryOfCitizenship').closest('.form-wrapper').find('.js-citizenship-wrapper').append(html);
                            }
                        });
                    }

                    var coborrowerCitizen = $('.js-citizenship-wrapper').find('.coborrower-citizen-country')

                    for (var j = 0; j < coborrowerCitizen.length; j++) {
                        $(coborrowerCitizen[j]).parent().find('.option').each(function () {
                            if ($(this).attr('country-value') == cont[j + 1]) {
                                $(this).addClass('selected').trigger('click');
                            }
                        });
                    }
                }
                if (data.Personal.CoBorrowerOccupation !== '') {
                    $('#coBorrowerOccupation').parent().find('.option').each(function () {
                        if ($(this).attr('occupation-value') == data.Personal.CoBorrowerOccupation) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }
                if (data.Personal.CoBorrowerTimeInThisJobYears !== '') {
                    $('#coBorrowerTimeInJobYears').parent().find('.option').each(function () {
                        if ($(this).text().replace('years', '').replace('year', '').trim() == data.Personal.CoBorrowerTimeInThisJobYears) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }
                if (data.Personal.CoBorrowerPreviousOccupation !== '') {
                    $('#coBorrowerPreviousOccupation').parent().find('.option').each(function () {
                        if ($(this).attr('occupation-value') == data.Personal.CoBorrowerPreviousOccupation) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }
                if (data.Personal.CoBorrowerPreviousTimeInThisJobYears !== '') {
                    $('#coBorrowerPreviousTimeInJobYears').parent().find('.option').each(function () {
                        if ($(this).text().replace('years', '').replace('year', '').trim() == data.Personal.CoBorrowerPreviousTimeInThisJobYears) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                }
                $('#coborrowertimeAtAddressYears').trigger('change');
            }

            if (data.Asset) {
                if (data.Asset.OwnHome === true && data.Asset.RentHome == false) {
                    $('#rentOrOwn').val('Own').trigger('change');
                    $('#mortgageAssetValue').val(data.Asset.MortgateValue);
                    $('#mortgageAssetMonthlyPayments').val(data.Asset.MortgateMonthlyPayment);
                    $('#mortgageAssetBalance').val(data.Asset.MortgateBalance);
                    $('#rentOrOwnOwn').prop('checked', true)
                } else if (data.Asset.OwnHome === false && data.Asset.RentHome == true) {
                    $('#rentOrOwn').val('Rent').trigger('change');
                    $('#mortgageRentMonthlyPayments').val(data.Asset.MortgageRentPaymentAmount);
                    $('#rentOrOwnRent').prop('checked', true)
                } else {
                    $('#rentOrOwn').val('Select')
                }

                if (data.Asset.MortgateMonthlyPayment !== 0) {
                    $('#MortgageRentPaymentAmount').val(data.Asset.MortgageRentPaymentAmount);
                }

                if (data.Asset.MortgateValue !== 0) {
                    $('#mortgageAssetValue').val(data.Asset.MortgateValue);
                }

                if (data.Asset.VehicleValue !== 0) {
                    $('#VehicleValue').val(data.Asset.VehicleValue);
                }

                if (data.Asset.FurnitureValue !== 0) {
                    $('#FurnitureValue').val(data.Asset.FurnitureValue);
                }

                if (data.Asset.CreditCardAmount !== 0) {
                    $('#CreditCardAmount').val(data.Asset.CreditCardAmount);
                }

                if (data.Asset.CreditCardMonthlyPayment !== 0) {
                    $('#CreditCardMonthlyPayment').val(data.Asset.CreditCardMonthlyPayment);
                }

                if (data.Asset.MortgateBalance !== 0) {
                    $('#mortgageAssetBalance').val(data.Asset.MortgateBalance);
                }

                if (data.Asset.MortgateMonthlyPayment) {
                    $('#mortgageAssetMonthlyPayments').val(data.Asset.MortgateMonthlyPayment);
                }

                if (data.Asset.PersonalLoanAmount !== 0) {
                    $('#PersonalLoanAmount').val(data.Asset.PersonalLoanAmount);
                }

                if (data.Asset.PersonalLoanMonthlyPayment !== 0) {
                    $('#PersonalLoanMonthlyPayment').val(data.Asset.PersonalLoanMonthlyPayment);
                }

                if (!!data.Asset.CouncilRatesExpenditureValueFrequency) {
                    $('#councilRatesExpenditureValueFrequency').val(data.Asset.CouncilRatesExpenditureValueFrequency)
                }

                if (!!data.Asset.UtilitiesExpenditureValueFrequency) {
                    $('#utilitiesExpenditureValueFrequency').val(data.Asset.UtilitiesExpenditureValueFrequency)
                }

                if (!!data.Asset.VehicleExpenditureValueFrequency) {
                    $('#vehicleExpenditureValueFrequency').val(data.Asset.VehicleExpenditureValueFrequency)
                }

                if (!!data.Asset.HomeInsuranceExpenditureValueFrequency) {
                    $('#vehicleExpenditureValueFrequency').val()
                }

                if (!!data.Asset.LivingExpenditureValueFrequency) {
                    $('#livingExpenditureValueFrequency').val(data.Asset.LivingExpenditureValueFrequency)
                }
            }

            if (data.OtherAssets.length > 0) {
                data.OtherAssets.each(function (i, index) {
                    var template = $('.temp-other-asset').html();
                    $('.js-asset-group').append(template);
                    var el = $($('.js-asset-group').find('.new-assets-field')[index]).parent();
                    el.find('.bka-dropdown').find('input').parent().find('.option').each(function () {
                        if ($(this).attr('asset-value') == i.AssetType) {
                            $(this).addClass('selected').trigger('click');
                        }
                    });
                    el.find('.assets-item-input').find('input').attr('value', i.Amount);
                });
                $('.js-amount-validate').on('change', function () {
                    _validateAmount($(this));
                });
                // format money control when it is being added
                var moneyControlElem = $('.js-money-control')
                formatMoneyControl(moneyControlElem)
            }

            if (data.Asset) {
                if (data.Asset.CouncilRatesExpenditureValue !== 0) $('#ratesExpenditureValue').val(data.Asset.CouncilRatesExpenditureValue);
                if (data.Asset.HomeInsuranceExpenditureValue !== 0) $('#insuranceExpenditureValue').val(data.Asset.HomeInsuranceExpenditureValue);
                if (data.Asset.UtilitiesExpenditureValue !== 0) $('#utilitiesExpenditureValue').val(data.Asset.UtilitiesExpenditureValue);
                if (data.Asset.LivingExpenditureValue !== 0) $('#livingExpenditureValue').val(data.Asset.LivingExpenditureValue);
                if (data.Asset.VehicleExpenditureValue !== 0) $('#vehicleExpenditureValue').val(data.Asset.VehicleExpenditureValue);

                if (data.Asset.OtherRecurringExpenses.length > 0) {
                    data.Asset.OtherRecurringExpenses.each(function (i, index) {
                        $('.js-household-expenditure-group').append(getTemplateAsset(index));
                        var el = $($('.js-household-expenditure-group').find('.new-assets-field')[index]).parent();
                        el.find('.bka-dropdown').find('input').parent().find('.option').each(function () {
                            if ($(this).attr('expense-value') == i.ExpenseType) {
                                $(this).addClass('selected').trigger('click');
                            }
                        });
                        el.find('#assets-item-frequency-input').find('input').attr('value', i.Frequency) // populate frequency value to other asset frequency input
                        el.find('#assets-item-payment-input').find('input').attr('value', i.Amount)
                    });
                    $('.js-amount-validate').on('change', function () {
                        _validateAmount($(this));
                    });
                    // format money control when it is being added
                    var moneyControlElem = $('.js-money-control')
                    formatMoneyControl(moneyControlElem)
                    initSumup(".sum-up2", ".assets-item-total2");
                }
            }

            window.setTimeout(function () {
                sumupBoth()
                // Duplicated Function
                $('.js-money-control').each(function () {
                    $(this).trigger('keyup');
                });
            }, 0);

            if (!!data.IsPostCall) {
                $('.js-goods-type > h1').after(`
                    <p class="top-is-post-text is-post-text">
                        We've included some information from your most recent loan in the form below.You just need to review this information and update anything if necessary.
                    </p>
                `)

                $('.js-your-details-go-next, .js-about-you-go-next, .js-employment-details-go-next , .js-financial-position-finish').before(`
                <p class="is-post-text">
                    By clicking “next” you are confirming that the above information is correct.
                </p>`)

                $('.js-financial-position-finish').before(`
                <div class="home-wrapper last-home-wrapper">
                    <div class="tick-description"> 
                        <div class="tick-description__checkbox"> 
                            <div class="checkbox js-post-checkbox"></div> 
                            <input class="tick d-none js--post-terms-acceptance" type="checkbox" name="name" value="" autocomplete="off"> 
                        </div> 
                        <div class="tick-description__text"> 
                            <span> By ticking this box, you confirm that: </span> 
                            <ul class="tick-description-list"> 
                                <li style="margin: 10px 0;">You have checked all information automatically entered into the application and updated where necessary.</li> 
                                <li style="margin-bottom: 10px;">You are not currently bankrupt or have a history of credit defaults</li> 
                                <li style="margin-bottom: 10px;">You confirm the UDC Privacy Statement applies to your application</li> 
                                <li style="margin-bottom: 10px;">Your application is subject to UDC's credit approval process. Lending criteria applied. </li> 
                            </ul> 
                        </div> 
                    </div>
                </div>`)

                $('.js-financial-position-finish').attr('disabled', 'disabled')

                $('.js-post-checkbox').on('click', function () {
                    $(this).parent().find('input').trigger('click')
                    if ($(this).parent().find('input').prop('checked')) {
                        $(this).addClass('checked')
                        $('.js-financial-position-finish').removeAttr("disabled")
                    } else {
                        $(this).removeClass('checked')
                    }

                    if ($(this).next().prop('checked')) {
                        $(this).addClass('checked')
                        $('.js-financial-position-finish').removeAttr("disabled")
                    } else {
                        $(this).removeClass('checked')
                    }
                })

                $('.js-your-details-go-next').on('click', () => validatePhone($('#mobileNumber')))
            }
        } catch (e) {}
    }

    return {
        setValues: _setValues,
        getData: _getData,
        isFormValid: _isFormValid,
        validateYourDetails: _validateYourDetails,
        validateAboutYou: _validateAboutYou,
        validateFinancialPosition: _validateFinancialPosition,
        validateEmployeementDetails: _validateEmployeementDetails,
        getOtherIncomes: _getOtherIncomes,
        getAssets: _getAssets,
        getOtherAssetAmountSum: _getOtherAssetAmountSum,
        getOtherMonthlyPaymentSum: _getOtherMonthlyPaymentSum,
        submitForm: _submitForm,
        validateAmount: _validateAmount,
        copyAddress: _copyAddress,
        bindForm: _bindForm,
        addPurchaseItem: _addPurchaseItem,
        getOtherFinancialCommits: _getOtherFinancialCommits,
        saveForm: _saveForm
    }
})();
